import * as types from './mutation-types'
import { MutationTree } from 'vuex'
import LoyaltyProgramState from '../types/LoyaltyProgramState'

export const mutations: MutationTree<LoyaltyProgramState> = {
  [types.LOYALTY_TIERS] (state, tiers) {
    state.tiers = tiers
  },
  [types.LOYALTY_CUSTOMER] (state, customer) {
    state.customer = customer
  },
  [types.LOYALTY_DASHBOARD] (state, dashboard) {
    state.dashboard = dashboard
  },
  [types.LOYALTY_CART_SET] (state, items) {
    state.cartItems = items
  },
  [types.LOYALTY_CART_ADD_ITEM] (state, { product }) {
    const record = state.cartItems.find(p => p.sku === product.sku)
    if (!record) {
      let item = {
        ...product,
        qty: parseInt(product.qty ? product.qty : 1)
      }
      state.cartItems.push(item)
    } else {
      record.qty += parseInt((product.qty ? product.qty : 1))
    }
  },
  [types.LOYALTY_CART_DEL_ITEM] (state, { product, removeByParentSku = true }) {
    state.cartItems = state.cartItems.filter(p => p.sku !== product.sku && (p.parentSku !== product.sku || removeByParentSku === false))
  },
  [types.LOYALTY_CART_UPD_ITEM] (state, { product, qty }) {
    const record = state.cartItems.find(p => p.sku === product.sku)

    if (record) {
      record.qty = parseInt(qty)
    }
  }
}
