import Vue from 'vue'
import rootStore from '@vue-storefront/core/store'
import { calculateProductTax } from '@vue-storefront/core/modules/catalog/helpers/tax'
import debounce from 'lodash-es/debounce'
import flattenDeep from 'lodash-es/flattenDeep'
import omit from 'lodash-es/omit'
import remove from 'lodash-es/remove'
import toString from 'lodash-es/toString'
import union from 'lodash-es/union'
import { optionLabel } from '@vue-storefront/core/modules/catalog/helpers/optionLabel'
import i18n from '@vue-storefront/i18n'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { getThumbnailPath } from '@vue-storefront/core/helpers'
import { Logger } from '@vue-storefront/core/lib/logger'
import { isServer } from '@vue-storefront/core/helpers'
import config from 'config'

function _prepareTicketOption (product) {
  let product_option = {
    extension_attributes: {
      custom_options: [],
      configurable_item_options: [],
      bundle_options: [],
      tickets: []
    }
  }

  return product_option
}

export function setCustomTicketOptionsAsync (context, { product, customOptions }) {
  const productOption = _prepareTicketOption(product)
  productOption.extension_attributes.tickets = customOptions
  return productOption
}

export function findConfigurableChildAsync ({ product, configuration = null, selectDefaultChildren = false, availabilityCheck = true }) {
  let selectedVariant = product.configurable_children.find((configurableChild) => {
    if (availabilityCheck) {
      if (configurableChild.stock && !config.products.listOutOfStockProducts) {
        if (!configurableChild.stock.is_in_stock) {
          return false
        }
      }
    }
    if (configurableChild.status >= 2/** disabled product */) {
      return false
    }
    if (selectDefaultChildren) {
      return true // return first
    }
    if (configuration.sku) {
      return configurableChild.sku === configuration.sku // by sku or first one
    } else {
      return Object.keys(omit(configuration, ['price'])).every((configProperty) => {
        if (!configuration[configProperty] || typeof configuration[configProperty].id === 'undefined') return true // skip empty
        return toString(configurableChild[configProperty]) === toString(configuration[configProperty].id)
      })
    }
  })
  return selectedVariant
}

export function syncProductPrice (product, backProduct) { // TODO: we probably need to update the Net prices here as well
  product.sgn = backProduct.sgn // copy the signature for the modified price
  product.priceInclTax = backProduct.price_info.final_price
  product.originalPriceInclTax = backProduct.price_info.regular_price
  product.specialPriceInclTax = backProduct.price_info.special_price

  product.special_price = backProduct.price_info.extension_attributes.tax_adjustments.special_price
  product.price = backProduct.price_info.extension_attributes.tax_adjustments.final_price
  product.originalPrice = backProduct.price_info.extension_attributes.tax_adjustments.regular_price

  product.priceTax = product.priceInclTax - product.price
  product.specialPriceTax = product.specialPriceInclTax - product.special_price
  product.originalPriceTax = product.originalPriceInclTax - product.originalPrice

  if (product.priceInclTax >= product.originalPriceInclTax) {
    product.specialPriceInclTax = 0
    product.special_price = 0
  }
  Vue.prototype.$bus.$emit('product-after-priceupdate', product)
  // Logger.log(product.sku, product, backProduct)()
  return product
}
/**
 * Synchronize / override prices got from ElasticSearch with current one's from Magento2 or other platform backend
 * @param {Array} products
 */
export const doPlatformPricesSync = debounce((products) => {
  return new Promise((resolve, reject) => {
    if (config.products.alwaysSyncPlatformPricesOver) {
      if (config.products.clearPricesBeforePlatformSync) {
        for (let product of products) { // clear out the prices as we need to sync them with Magento
          product.priceInclTax = null
          product.originalPriceInclTax = null
          product.specialPriceInclTax = null

          product.special_price = null
          product.price = null
          product.originalPrice = null

          product.priceTax = null
          product.specialPriceTax = null
          product.originalPriceTax = null

          if (product.configurable_children) {
            for (let sc of product.configurable_children) {
              sc.priceInclTax = null
              sc.originalPriceInclTax = null
              sc.specialPriceInclTax = null

              sc.special_price = null
              sc.price = null
              sc.originalPrice = null

              sc.priceTax = null
              sc.specialPriceTax = null
              sc.originalPriceTax = null
            }
          }
        }
      }

      let skus = products.map((p) => { return p.sku })

      if (products.length === 1) { // single product - download child data
        const childSkus = flattenDeep(products.map((p) => { return (p.configurable_children) ? p.configurable_children.map((cc) => { return cc.sku }) : null }))
        skus = union(skus, childSkus)
      }
      if (skus && skus.length > 0) {
        Logger.log('Starting platform prices sync for', skus) // TODO: add option for syncro and non syncro return()
        rootStore.dispatch('product/syncPlatformPricesOver', { skus: skus }, { root: true }).then((syncResult) => {
          if (syncResult) {
            syncResult = syncResult.items || []

            for (let product of products) {
              const backProduct = syncResult.find((itm) => { return itm.id === product.id })
              if (backProduct) {
                product.price_is_current = true // in case we're syncing up the prices we should mark if we do have current or not
                product.price_refreshed_at = new Date()
                product = syncProductPrice(product, backProduct)

                if (product.configurable_children) {
                  for (let configurableChild of product.configurable_children) {
                    const backProductChild = syncResult.find((itm) => { return itm.id === configurableChild.id })
                    if (backProductChild) {
                      configurableChild = syncProductPrice(configurableChild, backProductChild)
                    }
                  }
                }
              }
            }
          }
          resolve(products)
        })
      } else { // empty list of products
        resolve(products)
      }
      if (!config.products.waitForPlatformSync && !isServer) {
        Logger.log('Returning products, the prices yet to come from backend!')()
        for (let product of products) {
          product.price_is_current = false // in case we're syncing up the prices we should mark if we do have current or not
          product.price_refreshed_at = null
        }
        resolve(products)
      }
    } else {
      resolve(products)
    }
  })
}, 4000, {
  leading:true,
  trailing:false
})

/**
 * Calculate taxes for specific product collection
 */
export function calculateTaxes (products, store) {
  return new Promise((resolve, reject) => {
    if (config.tax.calculateServerSide) {
      Logger.debug('Taxes calculated server side, skipping')()
      doPlatformPricesSync(products).then((products) => {
        resolve(products)
      })
    } else {
      const storeView = currentStoreView()
      store.dispatch('tax/list', { query: '' }, { root: true }).then((tcs) => { // TODO: move it to the server side for one requests OR cache in indexedDb
        for (let product of products) {
          product = calculateProductTax(product, tcs.items, storeView.tax.defaultCountry, storeView.tax.defaultRegion, storeView.tax.sourcePriceIncludesTax)
        }
        doPlatformPricesSync(products).then((products) => {
          resolve(products)
        })
      }) // TODO: run Magento2 prices request here if configured so in the config
    }
  })
}

function _prepareProductOption (product) {
  let product_option = {
    extension_attributes: {
      custom_options: [],
      configurable_item_options: [],
      bundle_options: []
    }
  }
  /* if (product.product_option) {
    product_option = product.product_option
  } */
  return product_option
}
export function setConfigurableProductOptionsAsync (context, { product, configuration }) {
  if (product.configurable_options) {
    const product_option = _prepareProductOption(product)
    /* eslint camelcase: "off" */
    const configurable_item_options = product_option.extension_attributes.configurable_item_options
    for (const configKey of Object.keys(configuration)) {
      const configOption = configuration[configKey]
      if (configOption.attribute_code && configOption.attribute_code !== 'price') {
        const option = product.configurable_options.find(co => {
          return (co.attribute_code === configOption.attribute_code)
        })

        if (!option) {
          Logger.error('Wrong option id for setProductOptions', configOption.attribute_code)()
          return null
        }
        let existingOption = configurable_item_options.find(cop => {
          return cop.option_id === option.attribute_id
        })
        if (!existingOption) {
          existingOption = {
            option_id: option.attribute_id,
            option_value: configOption.id,
            label: i18n.t(configOption.attribute_code),
            value: configOption.label
          }
          configurable_item_options.push(existingOption)
        }
        existingOption.option_value = configOption.id
        existingOption.label = i18n.t(configOption.attribute_code)
        existingOption.value = configOption.label
      }
    }
    // Logger.debug('Server product options object', product_option)()
    return product_option
  } else {
    return null
  }
}

function _internalMapOptions (productOption) {
  const optionsMapped = []
  for (let option of productOption.extension_attributes.configurable_item_options) {
    optionsMapped.push({
      label: option.label,
      value: option.value
    })
  }
  productOption.extension_attributes.configurable_item_options = productOption.extension_attributes.configurable_item_options.map((op) => {
    return omit(op, ['label', 'value'])
  })
  return optionsMapped
}

export function populateProductConfigurationAsync (context, { product, selectedVariant }) {
  if (product.configurable_options) {
    for (let option of product.configurable_options) {
      let attribute_code
      let attribute_label
      if (option.attribute_code) {
        attribute_code = option.attribute_code
        attribute_label = option.label ? option.label : (option.frontend_label ? option.frontend_label : option.default_frontend_label)
      } else {
        if (option.attribute_id) {
          let attr = context.rootState.attribute.list_by_id[option.attribute_id]
          if (!attr) {
            Logger.error('Wrong attribute given in configurable_options - can not find by attribute_id', option)()
            continue
          } else {
            attribute_code = attr.attribute_code
            attribute_label = attr.frontend_label ? attr.frontend_label : attr.default_frontend_label
          }
        } else {
          Logger.error('Wrong attribute given in configurable_options - no attribute_code / attribute_id', option)()
        }
      }
      let selectedOption = null
      if (selectedVariant.custom_attributes) {
        selectedOption = selectedVariant.custom_attributes.find((a) => { // this is without the "label"
          return (a.attribute_code === attribute_code)
        })
      } else {
        selectedOption = {
          attribute_code: attribute_code,
          value: selectedVariant[attribute_code]
        }
      }
      const selectedOptionMeta = option.values.find(ov => { return ov.value_index === selectedOption.value })
      if (selectedOptionMeta) {
        selectedOption.label = selectedOptionMeta.label ? selectedOptionMeta.label : selectedOptionMeta.default_label
        selectedOption.value_data = selectedOptionMeta.value_data
      }

      const confVal = {
        attribute_code: attribute_code,
        id: selectedOption.value,
        label: selectedOption.label ? selectedOption.label : /* if not set - find by attribute */optionLabel(context.rootState.attribute, { attributeKey: selectedOption.attribute_code, searchBy: 'code', optionId: selectedOption.value })
      }
      context.state.current_configuration[attribute_code] = confVal
    }
    if (config.cart.setConfigurableProductOptions) {
      const productOption = setConfigurableProductOptionsAsync(context, { product: product, configuration: context.state.current_configuration }) // set the custom options
      if (productOption) {
        product.options = _internalMapOptions(productOption)
        product.product_option = productOption
      }
    }
  }
  return selectedVariant
}

export function configureProductAsync (context, { product, configuration, selectDefaultVariant = true, fallbackToDefaultWhenNoAvailable = true, setProductErorrs = false }) {
  // use current product if product wasn't passed
  if (product === null) product = context.getters.productCurrent
  const hasConfigurableChildren = (product.configurable_children && product.configurable_children.length > 0)

  if (hasConfigurableChildren) {
    // handle custom_attributes for easier comparing in the future
    product.configurable_children.forEach((child) => {
      let customAttributesAsObject = {}
      if (child.custom_attributes) {
        child.custom_attributes.forEach((attr) => {
          customAttributesAsObject[attr.attribute_code] = attr.value
        })
        // add values from custom_attributes in a different form
        Object.assign(child, customAttributesAsObject)
      }
    })
    // find selected variant
    let desiredProductFound = false
    let selectedVariant = findConfigurableChildAsync({ product, configuration, availabilityCheck: true })
    if (!selectedVariant) {
      if (fallbackToDefaultWhenNoAvailable) {
        selectedVariant = findConfigurableChildAsync({ product, selectDefaultChildren: true, availabilityCheck: true }) // return first available child
        desiredProductFound = false
      } else {
        desiredProductFound = false
      }
    } else {
      desiredProductFound = true
    }

    if (selectedVariant) {
      if (!desiredProductFound) { // update the configuration
        populateProductConfigurationAsync(context, { product: product, selectedVariant: selectedVariant })
        configuration = context.state.current_configuration
      }
      if (setProductErorrs) {
        product.errors = {} // clear the product errors
      }
      product.is_configured = true

      if (config.cart.setConfigurableProductOptions && !selectDefaultVariant && !(Object.keys(configuration).length === 1 && configuration.sku)) {
        // the condition above: if selectDefaultVariant - then "setCurrent" is seeting the configurable options; if configuration = { sku: '' } -> this is a special case when not configuring the product but just searching by sku
        const productOption = setConfigurableProductOptionsAsync(context, { product: product, configuration: configuration }) // set the custom options
        if (productOption) {
          selectedVariant.product_option = productOption
          selectedVariant.options = _internalMapOptions(productOption)
        }
      }/* else {
        Logger.debug('Skipping configurable options setup', configuration)()
      } */
      const fieldsToOmit = ['name']
      if (selectedVariant.image === '') fieldsToOmit.push('image')
      selectedVariant = omit(selectedVariant, fieldsToOmit) // We need to send the parent SKU to the Magento cart sync but use the child SKU internally in this case
      // use chosen variant
      if (selectDefaultVariant) {
        context.dispatch('setCurrent', selectedVariant)
      }
      Vue.prototype.$bus.$emit('product-after-configure', { product: product, configuration: configuration, selectedVariant: selectedVariant })
    }
    if (!selectedVariant && setProductErorrs) { // can not find variant anyway, even the default one
      product.errors.variants = i18n.t('No available product variants')
      if (selectDefaultVariant) {
        context.dispatch('setCurrent', product) // without the configuration
      }
    }
    return selectedVariant
  } else {
    if (fallbackToDefaultWhenNoAvailable) {
      return product
    } else {
      return null
    }
  }
}
