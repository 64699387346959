import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import UserState from '../types/UserState'
import { Logger } from '@vue-storefront/core/lib/logger'

const mutations: MutationTree<UserState> = {
  [types.USER_REWARD_ID_CHANGED] (state, rewardId) {
    state.rewardId = rewardId
  }
}

export default mutations
