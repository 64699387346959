import { GetterTree } from 'vuex'
import CartState from '@vue-storefront/core/modules/cart/types/CartState'
import RootState from '@vue-storefront/core/types/RootState'

const getters: GetterTree<CartState, RootState> = {
  getLoyaltyCartItems (state) {
    return (state.cartItems || []).filter(item => ((item.product_option || {}).extension_attributes || {}).qty_using_points > 0) || []
  }
}

export default getters
