import * as types from './mutation-types'
import { MutationTree } from 'vuex'
import StoredCardsState from '../types/StoredCardsState'

export const mutations: MutationTree<StoredCardsState> = {
  [types.ALL_CARDS] (state, cards) {
    let setDefault = []

    state.cards = (cards || []).map(card => {
      if (!card.cc_expiry_date) {
        console.log('Card has no expiry date', card)
        return card
      }

      let expiry = card.cc_expiry_date.split('/')
      let today = new Date()
      let expiryDate = new Date()

      expiryDate.setFullYear(expiry[1], expiry[0], 1)
      expiryDate.setMonth(expiryDate.getMonth() + 1) // subtract 3 months from expiry date
      expiryDate.setDate(1)

      if (expiryDate < today) {
        card.expired = true
      }

      return card
    }).sort((a, b) => {
      return new Date(a.created_at) < new Date(b.created_at) ? -1 : 1
    }).map(card => {
      if (!card.is_default) {
        return card
      }

      if (!setDefault[card.location_id]) {
        setDefault[card.location_id] = []
      }

      if (setDefault[card.location_id].includes(card.brand_id)) {
        return {
          ...card,
          is_default: false
        }
      } else {
        setDefault[card.location_id].push(card.brand_id)
        return {
          ...card,
          is_default: true
        }
      }
    }).sort((a, b) => {
      return a.is_default ? -1 : 1
    }).sort((a, b) => {
      return a.location_id > b.location_id ? -1 : 1
    })
  },
  [types.CUSTOMER_LOCATION] (state, location) {
    state.location = location
  }
}
