import { Module } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import BrandsState from '../types/BrandsState'
import { mutations } from './mutation'
import { getters } from './getters'
import { actions } from './actions'
import { state } from './state'

export const module: Module<BrandsState, RootState> = {
  namespaced: true,
  mutations,
  actions,
  getters,
  state
}
