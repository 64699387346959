import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import StockState from '@vue-storefront/core/modules/catalog/types/StockState'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import config from 'config'
import { processURLAddress } from '@vue-storefront/core/helpers'

const actions: ActionTree<StockState, RootState> = {
  /**
   * Reset current configuration and selected variatnts
   */
  async check (context, { product, qty = 1 }) {
    if (config.stock.synchronize) {
      const task: any = await TaskQueue.execute({ url: processURLAddress(`${config.stock.endpoint}/check?sku=${encodeURIComponent(product.sku)}`),
        payload: {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors'
        },
        product_sku: product.sku
      })
      return {
        min_qty: task.result ? task.result.min_qty : 0,
        qty: task.result ? task.result.qty : 0,
        status: task.result ? (task.result.is_in_stock ? 'ok' : 'out_of_stock') : 'ok',
        onlineCheckTaskId: task.task_id,
        callback_event: `stock_check_${product.sku}`
      }
    } else {
      return { qty: product.stock ? product.stock.qty : 0, status: product.stock ? (product.stock.is_in_stock ? 'ok' : 'out_of_stock') : 'volatile' } // if not online, cannot check the source of true here
    }
  },
}

export default actions
