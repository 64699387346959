import { Module } from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import CartState from '../types/CartState'
import config from 'config'

export const module: Module<CartState, any> = {
  state: {
    isMicrocartOpen: false,
    processing: false,
    itemsAfterPlatformTotals: {},
    platformTotals: null,
    platformTotalSegments: null,
    cartIsLoaded: false,
    cartServerToken: '', // server side ID to synchronize with Backend (for example Magento)
    shipping: [],
    hasFreeShipping: false,
    freeShippingDifference: 0,
    freeShippingThreshold: config.cart.freeShippingThreshold || -1,
    payment: [],
    cartItemsHash: '',
    cartServerLastSyncDate: 0,
    cartServerLastTotalsSyncDate: 0,
    cartItems: [], // TODO: check if it's properly namespaced,
    source: null
  },
  actions,
  getters,
  mutations
}
