import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import MyAccountState from '../types/MyAccountState'

export const mutations: MutationTree<MyAccountState> = {
  [types.MYACCOUNT_CHANGE_PERPAGE_PAGINATION] (state, perPage) {
    state.perPage = perPage
  },
  [types.MYACCOUNT_CHANGE_CURRENT_PAGINATION] (state, current) {
    state.current = current
  },
  [types.MYACCOUNT_RESET_CURRENT_PAGINATION] (state, current) {
    state.current = current
  }
}
