import { extendModule, VueStorefrontModule } from '@vue-storefront/core/lib/module'

import { Catalog } from '@vue-storefront/core/modules/catalog'
import { Cart } from '@vue-storefront/core/modules/cart'
import { Checkout } from '@vue-storefront/core/modules/checkout'
import { Compare } from '@vue-storefront/core/modules/compare'
import { Review } from '@vue-storefront/core/modules/review'
import { Mailer } from '@vue-storefront/core/modules/mailer'
import { Wishlist } from '@vue-storefront/core/modules/wishlist'
import { Newsletter } from '@vue-storefront/core/modules/newsletter'
import { Notification } from '@vue-storefront/core/modules/notification'
import { RecentlyViewed } from '@vue-storefront/core/modules/recently-viewed'
import { Url } from '@vue-storefront/core/modules/url'
import { Homepage } from './homepage'
import { Claims } from './claims'
import { PromotedOffers } from './promoted-offers'
import { Ui } from './ui-store'
import { GoogleAnalytics } from './google-analytics'
// import { Hotjar } from './hotjar';
import { googleTagManager } from './google-tag-manager'
import { VsfGoogleTagManager } from './vsf-google-tag-manager'
import { VsfGtag } from './vsf-gtag'
// import { AmpRenderer } from './amp-renderer'
import { PaymentBackendMethods } from './payment-backend-methods'
// import { PaymentCashOnDelivery } from './payment-cash-on-delivery'
import { RawOutputExample } from './raw-output-example'
import { InstantCheckout } from './instant-checkout'
import { Storyblok, urlExtend } from './vsf-storyblok-module'
// import { PaymentCybersource } from './vsf-payment-cybersource'
import { AmazonPay } from './vsf-payment-amazon'
import { OrderHistory } from './order-history'
// import { Avalara } from './vsf-avalara'
import { VsfForceLogin, userExtend } from './vsf-force-login'
// import { rakutenLinkshareDataLayer } from './vsf-rakuten-linkshare-datalayer'
import { Klaviyo } from './vsf-klaviyo'
import { UserExtend as VSFPasswordReset} from './vsf-password-reset'
import { FacebookPixel } from './vsf-facebook-pixel'
// import { PaymentAfterpay } from './vsf-afterpay'
// import { Refersion } from './vsf-refersion'
// import { RewardStyle } from './vsf-rewardstyle'
import { QueryPromosModule } from './vsf-query-promos'
import { extendMappingFallback, Payload } from './vsf-mapping-fallback'
import { forProduct, forCategory, tap } from './vsf-mapping-fallback/builtin'
import { forStoryblok } from './vsf-storyblok-module/mappingFallback'
import { forSku, forProductId } from './urlSku/forSku'
import { VsfForceGuestCart, cartExtend } from './vsf-force-guest-cart'

// @ts-ignore
import themeModules, { register } from 'theme/modules';
import { GoogleAnalyticsExtended } from './google-analytics-extend';

register()
extendModule(urlExtend)
extendModule(userExtend)
extendModule(VSFPasswordReset)
extendModule(cartExtend)

/**
 * Some of the modules are registered lazily only when components from the module are appearing on current page.
 * If you want to use this module in pages without its components you need to remember about registering module first
 * In VS 1.8 this modules will be seamlessly lazyLoaded after proper action dispatch
 * - Wishlist
 */
export const registerModules: VueStorefrontModule[] = [
  // VsfForceLogin,
  Checkout,
  Catalog,
  Cart,
  Compare,
  Review,
  Mailer,
  Wishlist,
  Newsletter,
  Notification,
  Ui,
  RecentlyViewed,
  Homepage,
  Claims,
  PromotedOffers,
  // googleTagManager,
  VsfGtag,
  // VsfGoogleTagManager,
  // GoogleAnalytics,
  // Hotjar,
  PaymentBackendMethods,
  // PaymentCashOnDelivery,
  RawOutputExample,
  // AmpRenderer,
  InstantCheckout,
  Url,
  OrderHistory,
  Storyblok,
  AmazonPay,
  // PaymentCybersource,
  // PaymentAfterpay,
  // Avalara,
  // rakutenLinkshareDataLayer,
  // GoogleAnalytics,
  GoogleAnalyticsExtended,
  // Hotjar,
  FacebookPixel,
  Klaviyo,
  // Refersion,
  // RewardStyle,
  QueryPromosModule,
  // VsfForceGuestCart,
  ...themeModules
  // Example
]

extendMappingFallback(
  forSku, forProductId, forProduct, forCategory, forStoryblok, () => { return {redirect: '/page-not-found'} }
)
