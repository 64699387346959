import debounce from 'lodash-es/debounce'
import * as types from "../store/mutation-types";
export async function afterRegistration ({ Vue, config, store, isServer }) {
  store.subscribe((mutation, state) => {
    const type = mutation.type

    if (type.endsWith(types.ALL_BRANDS)) {
      Vue.prototype.$db.brandsCollection.setItem('brands', state.brands.brands).catch((reason) => {
        console.error(reason)
      })
    }
  })

  if (!isServer) {
    let getBrands = debounce(function () {
      store.dispatch('brands/getBrands', {
        'searchCriteria': ''
      })
    }, 10000, { leading:true, trailing:false })

    getBrands();

    store.dispatch('brands/syncBrands')
  }


}
