import { GetterTree } from 'vuex'
import LoyaltyProgramState from '../types/LoyaltyProgramState'

export const getters: GetterTree<LoyaltyProgramState, any> = {
  getTiers: state => state.tiers,
  getCustomer: state => state.customer,
  getCustomerAccounts: state => state.customer ? state.customer.accounts : [],
  getDashboard: state => state.dashboard,
  getDashboardAccounts: state => state.dashboard ? state.dashboard.accounts : [],
  getCurrentTier: state => state.dashboard ? state.dashboard.current_tier : '',
  getNextTier: state => state.dashboard ? state.dashboard.next_tier : '',
  getMilestoneTier: state => state.dashboard ? state.dashboard.milestone_tier : '',
  getCart: state => state.cartItems
}
