import { isServer } from '@vue-storefront/core/helpers'
import { Route } from 'vue-router';
import rootStore from '@vue-storefront/core/store'
import debounce from 'lodash-es/debounce'

const loadCards = debounce(function () {
  rootStore.dispatch('storedCards/getStoredCards')
}, 30000, { leading: true, trailing: false })

export function afterEach (to: Route, from: Route) {
  if (!isServer && rootStore.getters['user/isLoggedIn'] && from.name) {
    loadCards()
  }
}
