import SearchQuery from '@vue-storefront/core/lib/search/searchQuery';
import config from 'config'
import { extendMappingFallback, Payload } from '../vsf-mapping-fallback'
import { removeStoreCodeFromRoute } from '@vue-storefront/core/lib/multistore'

export const forSku = async ({ dispatch }, { url, params }: Payload) => {
  url = (removeStoreCodeFromRoute(url) as string)
  if (url.startsWith('p/') && config.seo.useUrlDispatcher) {
    const productQuery = new SearchQuery()
    const productSlug = url.split('/').reverse()[0]
    productQuery.applyFilter({key: 'sku', value: {'eq': productSlug}})
    const products = await dispatch('product/list', {query: productQuery}, {root: true})
    if (products && products.items && products.items.length) {
      const product = products.items[0]
      return {
        name: 'product',
        redirect: '/' + product.slug,
        query: params
      }
    }
  }
}
export const forProductId = async ({dispatch}, {url, params}: Payload) => {
  // https://www.randco.com/catalog/product/view/id/442
  url = (removeStoreCodeFromRoute(url) as string)
  if (url.startsWith('catalog/product/view/id') && config.seo.useUrlDispatcher) {
    const productQuery = new SearchQuery()
    const productId = url.split('/').reverse()[0]
    productQuery.applyFilter({key: 'id', value: {'eq': productId}})
    const products = await dispatch('product/list', {query: productQuery}, {root: true})
    if (products && products.items && products.items.length) {
      const product = products.items[0]
      return {
        name: 'product',
        redirect: '/' + product.slug,
        query: params
      }
    }
  }
}
