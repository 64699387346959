import {datadogRum} from '@datadog/browser-rum'
import Vue from "vue";

export async function afterRegistration({Vue, config, store, isServer}) {
  if (isServer) return

  Vue.prototype.$bus.$on('order-after-placed', async (data) => {
    datadogRum.addAction('Order Placed', {order: data})
  })

  store.subscribe((mutation, state) => {
    const type = mutation.type;

    if (type.endsWith('cart/cart/ADD')) {
      datadogRum.addAction('Add to Cart', {product: mutation.payload.product})
    }

    if (type.endsWith('cart/cart/DEL')) {
      datadogRum.addAction('Remove from Cart', {product: mutation.payload.product})
    }

    if (type.endsWith('category/category/UPD_CURRENT_CATEGORY')) {
      datadogRum.addAction('Category Impression', {category: mutation.payload})
    }

    if (type.endsWith('product/product/SET_CURRENT')) {
      datadogRum.addAction('Product Impression', {product: mutation.payload})
    }

  })
}
