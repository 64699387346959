declare const window
export function afterRegistration ({ Vue, config, store, isServer }) {
  function applyClientId (product) {
    window.ga = window.ga || function (func) {}
    window.ga((tracker) => {
      let clientId = tracker.get('clientId')
      let prod = product.product
      prod.product_option = prod.product_option || {}
      prod.product_option.extension_attributes = prod.product_option.extension_attributes || {}
      prod.product_option.extension_attributes.ga_client_id = clientId
    })
  }

  if (config.analytics.id && !isServer) {
    Vue.prototype.$bus.$on('cart-before-add', product => {
      applyClientId(product)
    })

    Vue.prototype.$bus.$on('cart-before-update', product => {
      applyClientId(product)
    })
  }
}
