import * as types from './../store/mutation-types'

export async function afterRegistration ({ Vue, config, store, isServer }) {
  if (!isServer) {
    Vue.prototype.$bus.$on('user-after-loggedin', receivedData => {
      store.dispatch('storedCards/getStoredCards')
    })

    store.dispatch('storedCards/syncCards')
    store.dispatch('storedCards/syncLocation')
  }

  store.subscribe((mutation, state) => {
    const type = mutation.type

    if (type.endsWith(types.ALL_CARDS)) {
      Vue.prototype.$db.storedCardsCollection.setItem('customer-cards', state.storedCards.cards).catch((reason) => {
        console.error(reason)
      })
    }

    if (type.endsWith(types.CUSTOMER_LOCATION)) {
      Vue.prototype.$db.storedCardsCollection.setItem('customer-location', state.storedCards.location).catch((reason) => {
        console.error(reason)
      })
    }
  })
}
