import * as types from './../store/mutation-types'

export async function afterRegistration ({ Vue, config, store, isServer }) {
  if (!isServer) {
    store.dispatch('loyalty/syncCustomer')
    store.dispatch('loyalty/syncDashboard')
  }

  store.subscribe((mutation, state) => {
    const type = mutation.type

    if (type.endsWith(types.LOYALTY_CUSTOMER)) {
      Vue.prototype.$db.loyaltyProgramCollection.setItem('loyalty-customer', state.loyalty.customer).catch((reason) => {
        console.error(reason)
      })
    }

    if (type.endsWith(types.LOYALTY_DASHBOARD)) {
      Vue.prototype.$db.loyaltyProgramCollection.setItem('loyalty-dashboard', state.loyalty.dashboard).catch((reason) => {
        console.error(reason)
      })
    }
  })
}
