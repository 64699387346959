import * as types from './mutation-types'
import { MutationTree } from 'vuex'
import VersioningState from '../types/VersioningState'

export const mutations: MutationTree<VersioningState> = {
  [types.SET_VERSION] (state, version) {
    state.version = version
  },
  [types.SET_READY_TO_UPDATE] (state, bool) {
    state.readyToUpdate = bool
  },
  [types.SET_UI] (state, bool) {
    state.ui = bool
  },
  [types.SET_MODAL] (state, bool) {
    state.modal = bool
  }
}
