import { currentStoreView } from '@vue-storefront/core/lib/multistore'

const getDefaultShippingMethod = (shippingMethods = []) => {
  const onlineShippingMethods = shippingMethods.filter(shippingMethod => !shippingMethod.offline)
  if (!onlineShippingMethods.length) return

  return onlineShippingMethods.find(shippingMethod => !!shippingMethod.default) || onlineShippingMethods[0]
}

const getDefaultPaymentMethod = (paymentMethods => {
  if (!paymentMethods || !paymentMethods.length) return

  return paymentMethods.find(item => item.default) || paymentMethods[0]
})

const createOrderData = ({
                           shippingDetails,
                           shippingMethods,
                           paymentMethods,
                           paymentDetails,
                           taxCountry = currentStoreView().tax.defaultCountry
                         }) => {
  const country = shippingDetails.country ? shippingDetails.country : taxCountry
  const shipping = getDefaultShippingMethod(shippingMethods)
  const payment = getDefaultPaymentMethod(paymentMethods)

  return {
    country,
    shippingAddress: {
      firstname: shippingDetails.firstName,
      lastname: shippingDetails.lastName,
      city: shippingDetails.city,
      postcode: shippingDetails.zipCode,
      street: [shippingDetails.streetAddress, shippingDetails.apartmentNumber],
      email: shippingDetails.emailAddress,
      telephone: shippingDetails.phoneNumber,
      region_code: shippingDetails.state
    },
    billingAddress: {
      firstname: paymentDetails.firstName,
      lastname: paymentDetails.lastName,
      city: paymentDetails.city,
      postcode: paymentDetails.zipCode,
      street: [paymentDetails.streetAddress, paymentDetails.apartmentNumber],
      countryId: paymentDetails.country,
      email: paymentDetails.emailAddress,
      telephone: paymentDetails.phoneNumber,
      region_code: paymentDetails.state
    },
    method_code: shipping && shipping.method_code ? shipping.method_code : null,
    carrier_code: shipping && shipping.carrier_code ? shipping.carrier_code : null,
    payment_method: payment && payment.code ? payment.code : null
  }
}

export default createOrderData
