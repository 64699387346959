import { VueStorefrontModule, VueStorefrontModuleConfig } from '@vue-storefront/core/lib/module'
import { afterRegistration } from './hooks/afterRegistration'
import { beforeEach } from './router/beforeEach'

const KEY = 'cache-tools'

const moduleConfig: VueStorefrontModuleConfig = {
  key: KEY,
  afterRegistration,
  router: { beforeEach }
}

export const CacheTools = new VueStorefrontModule(moduleConfig)
