import { Route } from 'vue-router'
import rootStore from '@vue-storefront/core/store'
import { isServer } from '@vue-storefront/core/helpers'

export async function beforeEach(to: Route, from: Route, next) {
  const checkoutRoute = to.matched.some(route => route.name === 'checkout')
  const rewardsCartRoute = to.matched.some(route => route.name === 'rewards-cart')

  if (isServer) {
    next()
  } else {
    if (checkoutRoute || rewardsCartRoute) return next()
    // await rootStore.dispatch('cart/clearLoyaltyCartItems', {client: true, server: false})
    // await rootStore.dispatch('loyalty/clearCart')
    next()
  }
}
