import * as types from '@vue-storefront/core/modules/cart/store/mutation-types'
import config from 'config'
import i18n from '@vue-storefront/i18n'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import { Logger } from '@vue-storefront/core/lib/logger'
import Task from '@vue-storefront/core/lib/sync/types/Task'
import merge from 'lodash-es/merge'

const MAX_BYPASS_COUNT = 10
let _connectBypassCount = 0

function _getDifflogPrototype () {
  return {items: [], serverResponses: [], clientNotifications: []}
}

/** @todo: move this metod to data resolver; shouldn't be a part of public API no more */
async function _connect ({ guestCart = false, forceClientState = false }): Promise<Task> {
  const task = { url: guestCart ? config.cart.create_endpoint.replace('{{token}}', '') : config.cart.create_endpoint, // sync the cart
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    force_client_state: forceClientState,
    silent: true
  }
  return TaskQueue.execute(task)
}

export default {
  async connect ({ getters, dispatch, commit }, { guestCart = false, forceClientState = false }) {
    if (getters.isCartSyncEnabled) {
      return _connect({ guestCart, forceClientState }).then(task => {
        const cartToken = task.result

        if (task.resultCode === 200) {
          Logger.info('Server cart token created.', 'cart', cartToken)()
          commit(types.CART_LOAD_CART_SERVER_TOKEN, cartToken)
          return dispatch('cartPull', { update: false })
        } else {
          let resultString = task.result ? String(task.result) : null

          if (resultString && (resultString.indexOf(i18n.t('not authorized').toString()) < 0 && resultString.indexOf('not authorized')) < 0) { // not respond to unathorized errors here
            if (_connectBypassCount < MAX_BYPASS_COUNT) {
              Logger.log('Bypassing with guest cart' + _connectBypassCount, 'cart')()
              _connectBypassCount = _connectBypassCount + 1
              Logger.error(task.result, 'cart')()
              return dispatch('connect', { guestCart: true })
            }
          }
        }
      })
    } else {
      Logger.warn('Cart sync is disabled by the config', 'cart')()
      return _getDifflogPrototype()
    }
  },
  async sync ({ getters, rootGetters, commit, dispatch }, { forceClientState = false, dryRun = false }) { // pull current cart FROM the server
    return dispatch('cartPull', { update: false })
  },
  /** add discount code to the cart + refresh totals @description this method is part of "public" cart API */
  async applyCoupon ({ getters, dispatch }, couponCode) {
    if (getters.isTotalsSyncEnabled && getters.isCartConnected) {
      const task = await TaskQueue.execute({ url: config.cart.applycoupon_endpoint.replace('{{coupon}}', couponCode),
        payload: {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors'
        },
        silent: false
      })

      if (task.result === true) {
        dispatch('syncTotals', { forceServerSync: true })
        await dispatch('cart/cartPull', { update: true })
      }
      return task.result
    }
    return null
  },
  async updateQuantity ({ commit, dispatch, getters }, { product, qty, forceServerSilence = false }) {
    return dispatch('cartAddItem', { productToAdd: product, qty: qty, update: true })
  },
  async addItems ({commit, dispatch, getters}, { productsToAdd, forceServerSilence = false }) {
    let diffLog = _getDifflogPrototype()

    for (let product of productsToAdd) {
      let result = await dispatch('cartAddItem', { productToAdd: product, qty: product.qty, update: true })

      diffLog = merge(diffLog, result)
    }
    return diffLog
  },
  async removeItem ({ commit, dispatch, getters }, payload) {
    let product = payload

    if (payload.product) { // new call format since 1.4
      product = payload.product
    }

    return dispatch('cartRemoveItem', { product, update: true })
  }
}
