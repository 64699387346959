export const SN_CATEGORY = 'category'
export const CATEGORY_UPD_CATEGORIES = `${SN_CATEGORY}/UPD_CATEGORIES`
export const CATEGORY_UPD_ALL_CATEGORIES = `${SN_CATEGORY}/UPD_ALL_CATEGORIES`
export const CATEGORY_UPD_CURRENT_CATEGORY = `${SN_CATEGORY}/UPD_CURRENT_CATEGORY`
export const CATEGORY_UPD_CURRENT_CATEGORY_PATH = `${SN_CATEGORY}/UPD_CURRENT_CATEGORY_PATH`
export const CATEGORY_UPD_SEARCH_PRODUCT_QUERY = `${SN_CATEGORY}/UPD_SEARCH_PRODUCT_QUERY`
export const CATEGORY_ADD_AVAILABLE_FILTER = `${SN_CATEGORY}/ADD_AVAILABLE_FILTER`
export const CATEGORY_REMOVE_FILTERS = `${SN_CATEGORY}/REMOVE_FILTERS`
export const CATEGORY_SET_SEARCH_OPTIONS = `${SN_CATEGORY}/SET_SEARCH_OPTIONS`
export const CATEGORY_MERGE_SEARCH_OPTIONS = `${SN_CATEGORY}/MERGE_SEARCH_OPTIONS`
