import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import CategoryState from '../../types/CategoryState'

const mutations: MutationTree<CategoryState> = {
  [types.CATEGORY_UPD_ALL_CATEGORIES] (state, categories) {
    state.all_categories = categories
  }
}

export default mutations
