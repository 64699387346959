import {Module} from 'vuex'
import QuickOrderState from '../types/QuickOrderState'
import {blankOrder, CART_SOURCE_NAME, DEFAULT_SHEET_NAME, getAllowedBrandsHelper, getRulePrice} from '../helpers'
import {TaskQueue} from '@vue-storefront/core/lib/sync'
import rootStore from '@vue-storefront/core/store'
import config from 'config'
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'
import Vue from 'vue'
import {cacheStorage} from '../index'

export const module: Module<QuickOrderState, any> = {
  namespaced: true,
  state: {
    orders: [blankOrder()],
    currentOrder: 0,
    productList: {},
    childProducts: {},
    hasLoadedProducts: false,
    moneyTotals: {},
    pointsTotals: {},
    freeShippingMessage: ''
  },
  actions: {
    async productFetch ({dispatch}) {
      let query = new SearchQuery()

      query.applyFilter({})

      return dispatch('product/list', {
        query: (new SearchQuery()).applyFilter({
          key: 'brand_id',
          value: {'in': [1, 5, 6]}
        }),
        sort: 'position:desc',
        size: 1000,
        excludeFields: [
          'image', 'thumbnail', 'ingredients_long', 'ingredients_short', 'youtube_video_url', 'subtitle', 'new_image_sizing', 'howto_video',
          'short_description', 'background_video', 'description', 'media_gallery', 'role_page_background',
          'how_it_works', 'small_image', 'media_gallery', 'thumbnail'
        ]
      }, {root: true})
    },
    async fetchProductList ({commit, dispatch, state}) {
      commit('setHasLoadedProducts', false)

      let res = await dispatch('productFetch')

      if (res) {
        res.items.forEach((product) => {
          state.productList[product.sku] = product
          // put nested child products in separate bucket so we can quickly find them later for adding to cart
          // this adds a small amount to memory usage but makes the add-to-cart lookups O(1) instead of O(n) per linked or configurable product
          if (product.configurable_children && product.configurable_children.length > 0) {
            product.configurable_children.forEach(product => {
              if (!product) return
              state.childProducts[product.sku] = product
            })
          }
          if (product.product_links && product.product_links.length > 0 && product.product_links.find(link => link.link_type === 'associated')) {
            product.product_links.forEach(linkedProduct => {
              if (!linkedProduct) return
              state.childProducts[linkedProduct.sku] = linkedProduct
            })
          }
        })
      }
      commit('setHasLoadedProducts', !!Object.entries(state.productList).length)
    },
    updateOrderTitle ({commit}, {newTitle}) {
      commit('updateCurrentOrder', newTitle)
    },
    updateTotals ({commit, rootState}) {
      commit('updateTotals', {rootState})
      commit('updateFreeShippingMessage')
    },
    async saveOrder ({commit, dispatch, state}) {
      let token = rootStore.getters['user/getUserToken']
      let cartId = rootStore.getters['cart/getCartToken']

      let method = 'POST'
      let order = {
        order_data: JSON.stringify(state.orders[state.currentOrder].order_data),
        name: state.orders[state.currentOrder].name
      }

      if (state.orders[state.currentOrder].id) {
        order['id'] = state.orders[state.currentOrder].id
      }

      const reqBody = {token, cartId, order}

      const task = await TaskQueue.execute({
        url: `${config.quickorder.order_endpoint}`,
        payload: {
          method: method,
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          body: JSON.stringify(reqBody)
        },
        silent: true
      })

      if (task.resultCode === 200) {
        commit('updateOrderFromRemote', task.result)
      } else {
        if (Object.prototype.toString.call(task.result) === '[object String]') {
          console.error(task.result)
        } else if (task.result.errorMessage) {
          console.error(task.result.errorMessage)
        }
        console.error(`couldn't save order #${order['id']}`)
      }
    },
    async loadOrders ({commit, dispatch, rootState}) {
      let token = rootStore.getters['user/getUserToken']
      let cartId = rootStore.getters['cart/getCartToken']
      const task = await TaskQueue.execute({
        url: `${config.quickorder.order_endpoint}?token=${token}&cartId=${cartId}`,
        payload: {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors'
        },
        silent: true
      })

      if (task.resultCode === 200) {
        commit('updateStateFromRemote', task.result.items)
      } else {
        if (Object.prototype.toString.call(task.result) === '[object String]') {
          console.error(task.result)
        } else if (task.result.errorMessage) {
          console.error(task.result.errorMessage)
        }
        console.error(`couldn't get orders from remote`)
      }
      dispatch('updateTotals')
    },
    clearOrder ({commit, dispatch}) {
      commit('clearOrder')
      dispatch('saveOrder')
      dispatch('updateTotals')
    },
    async deleteOrder ({commit, dispatch, state}) {
      // if order list is empty after deletion, create a fresh order
      // otherwise move down one index (toward 0), unless deleting order #0
      let orderId = state.orders[state.currentOrder].id

      dispatch('deleteOrderRemote', orderId).then(res => {
        // only delete local if successfully deleted remote

        commit('deleteOrder', state.currentOrder)
        if (state.orders.length <= 0) {
          dispatch('createOrder')
        } else {
          if (state.currentOrder - 1 <= 0) {
            commit('updateCurrentOrder', 0)
          } else {
            commit('updateCurrentOrder', state.currentOrder - 1)
          }
        }
      }, error => {
        console.error(error)
      })
      dispatch('updateTotals')
    },
    async deleteOrderRemote ({commit}, orderId) {
      let token = rootStore.getters['user/getUserToken']
      let cartId = rootStore.getters['cart/getCartToken']
      let customerId = rootStore.state.user.current.id
      return new Promise(async (resolve, reject) => {
        const task = await TaskQueue.execute({
          url: `${config.quickorder.order_endpoint}${orderId}?cartId=${cartId}&token=${token}`,
          payload: {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({customerId: customerId}),
            mode: 'cors'
          },
          silent: true
        })

        if (task.resultCode === 200) {
          resolve(true)
        } else {
          if (Object.prototype.toString.call(task.result) === '[object String]') {
            reject(task.result)
          } else if (task.result.errorMessage) {
            reject(task.result.errorMessage)
          }
        }
      })
    },
    async clearSource ({commit, dispatch, state}) {
      cacheStorage.removeItem('source')
      await rootStore.dispatch('cart/setSource', null)
    },
    async setSource ({commit, dispatch, state}) {
      if (state.orders[state.currentOrder].name.includes(DEFAULT_SHEET_NAME)) {
        cacheStorage.setItem('source', CART_SOURCE_NAME)
        await rootStore.dispatch('cart/setSource', CART_SOURCE_NAME)
        return
      }

      cacheStorage.setItem('source', CART_SOURCE_NAME + '-custom')
      await rootStore.dispatch('cart/setSource', CART_SOURCE_NAME + '-custom')
    },
    async renameOrder ({commit, dispatch, state}, name) {
      let token = rootStore.getters['user/getUserToken']

      let current: any = Object.assign({}, state.orders[state.currentOrder])

      let order = {
        id: current.id,
        name: name,
        order_data: JSON.stringify(current.order_data)
      }

      const task = await TaskQueue.execute({
        url: config.quickorder.order_endpoint,
        payload: {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          body: JSON.stringify({token, order})
        },
        silent: true
      })

      if (task.resultCode === 200) {
        commit('updateOrderFromRemote', task.result)
        return true
      } else {
        if (Object.prototype.toString.call(task.result) === '[object String]') {
          console.error(task.result)
        } else if (task.result.errorMessage) {
          console.error(task.result.errorMessage)
        }
        console.error(`couldn't update order`)
        return false
      }
    },
    async createOrder ({commit, dispatch, state}) {
      let token = rootStore.getters['user/getUserToken']
      let blankOrder = {
        order_data: {},
        name: DEFAULT_SHEET_NAME
      }

      blankOrder.order_data = JSON.stringify(blankOrder.order_data)
      const task = await TaskQueue.execute({
        url: config.quickorder.order_endpoint,
        payload: {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          body: JSON.stringify({token, order: blankOrder})
        },
        silent: true
      })

      if (task.resultCode === 200) {
        let returnedOrder = task.result

        returnedOrder.order_data = JSON.parse(returnedOrder.order_data)
        commit('createOrderLocal', task.result)
        let newIndex = state.orders.length - 1

        commit('updateCurrentOrder', newIndex)
      } else {
        if (Object.prototype.toString.call(task.result) === '[object String]') {
          console.error(task.result)
        } else if (task.result.errorMessage) {
          console.error(task.result.errorMessage)
        }
        console.error(`couldn't create order`)
      }
      this.dispatch('updateTotals')
    },
    updateCurrentOrder ({commit, dispatch}, newIndex) {
      commit('updateCurrentOrder', newIndex)
      dispatch('updateTotals')
    },
    async addToOrder ({commit, dispatch, rootState}, payload) {
      commit('addToOrder', payload)
      await dispatch('saveOrder')
      dispatch('updateTotals')
    }
  },
  mutations: {
    setHasLoadedProducts (state, newProductLoadedState) {
      state.hasLoadedProducts = newProductLoadedState
    },
    createOrderLocal (state, newOrder) {
      state.orders = state.orders.concat(newOrder)
    },
    // eslint-disable-next-line camelcase
    deleteOrder (state, order_index) {
      state.orders.splice(order_index, 1)
    },
    clearOrder (state) {
      // need to remove keys 1 by 1 to preserve observability
      Object.keys(state.orders[state.currentOrder].order_data).forEach(key => {
        Vue.delete(state.orders[state.currentOrder].order_data, key)
      })
    },
    updateOrderTitle (state, newTitle) {
      state.orders[state.currentOrder].name = newTitle
    },
    updateOrderFromRemote (state, updatedOrder) {
      Object.keys(updatedOrder).forEach(key => {
        if (key === 'order_data') {
          Vue.set(state.orders[state.currentOrder], key, JSON.parse(updatedOrder[key]))
        } else {
          Vue.set(state.orders[state.currentOrder], key, updatedOrder[key])
        }
      })
    },
    async updateStateFromRemote (state, newState) {
      // need to parse each order_data before saving
      newState.forEach(order => {
        order.order_data = JSON.parse(order.order_data)
      })
      if (newState.length === 0) return
      let index = await cacheStorage.getItem('currentOrder')

      state.orders = newState
      state.currentOrder = newState[index] ? index : 0
    },
    addToOrder (state, {sku, moneyQuantity = null, pointsQuantity = null}) {
      if (!state.orders[state.currentOrder] && !(config.quickorder || {}).advanceFeatures) {
        cacheStorage.setItem('currentOrder', 0)
        state.currentOrder = 0
      }

      if (!state.orders[state.currentOrder].order_data[sku]) {
        Vue.set(state.orders[state.currentOrder].order_data, sku, {mq: 0, pq: 0})
      }
      if (moneyQuantity !== null) {
        Vue.set(state.orders[state.currentOrder].order_data[sku], 'mq', moneyQuantity)
      }
      if (pointsQuantity !== null) {
        Vue.set(state.orders[state.currentOrder].order_data[sku], 'pq', pointsQuantity)
      }
      if (state.orders[state.currentOrder].order_data[sku].mq === 0 && state.orders[state.currentOrder].order_data[sku].pq === 0) {
        Vue.delete(state.orders[state.currentOrder].order_data, sku)
      }
    },
    updateFreeShippingMessage (state, rootState) {
      if (!state.orders[state.currentOrder]) return

      let diff = 0
      let skus = Object.keys(state.orders[state.currentOrder].order_data)

      if (skus.length) {
        for (let i = 0; i < skus.length; i++) {
          // if order contains a Bleu item (check order skus against productList for brand_id), ship free (i.e. return 0)
          if (state.productList[skus[i]] && state.productList[skus[i]].brand_id === 5) {
            state.freeShippingMessage = "You've earned free shipping!"
            return
          }
          // else if order contains an "Intro Offer" (it's an attribute on the item in productList) return 0
          else if (state.productList[skus[i]] && state.productList[skus[i]].intro_offer === 1) {
            state.freeShippingMessage = "You've earned free shipping!"
            return
          }
        }
      }

      // else if customer is Ruby or Diamond tier, return 0
      let freeShipTiers = ["ruby", "diamond", "black_diamond"]
        if (rootState && rootState.user && freeShipTiers.includes(rootState.user.current.extension_attributes.loyalty_tier)) {
          state.freeShippingMessage = "You've earned free shipping!"
          return
        }
        // else do order total math against free ship minimum of $1500 and return difference
        else {
          let totals = Object.values(state.moneyTotals).reduce((prev, curr) => prev + curr, 0)
          diff = 1500 - totals
        }

      if (diff <= 0) {
        state.freeShippingMessage = "You've earned free shipping!"
      } else {
        state.freeShippingMessage = `$${diff.toFixed(0)} away from free shipping!`
      }
    },
    updateCurrentOrder (state, newOrderIndex) {
      cacheStorage.setItem('currentOrder', newOrderIndex)
      state.currentOrder = newOrderIndex
    },
    updateTotals (state, {rootState}) {
      // FIXME do by brand first, then sum each brand
      // FIXME add brands to state
      // first get list of brands from sku list and set up totals object
      let brandList = []

      if (!state.orders[state.currentOrder]) return

      Object.keys(state.orders[state.currentOrder].order_data).forEach(sku => {
        if (state.productList[sku]) {
          brandList.push(state.productList[sku].brand_id)
        }
      })
      brandList = getAllowedBrandsHelper(rootState.user.current)
      // eslint-disable-next-line camelcase
      brandList.forEach(brand_id => {
        state.moneyTotals[brand_id] = 0
        state.pointsTotals[brand_id] = 0
      })

      // then get totals for each brand. calling component can just sum the totals returned by the getter
      Object.keys(state.orders[state.currentOrder].order_data).forEach(sku => {
        if (!state.productList[sku]) return

        const bi = state.productList[sku].brand_id
        const price = getRulePrice(rootState, state.productList[sku])
        const priceMultiplier = state.productList[sku].point_spending_multiplier

        state.moneyTotals[bi] += price * state.orders[state.currentOrder].order_data[sku].mq
        state.pointsTotals[bi] += price * priceMultiplier * state.orders[state.currentOrder].order_data[sku].pq
      })
    }
  },
  getters: {
    getCurrentOrder (state) {
      return state.currentOrder
    },
    getOrderList (state) {
      // includes timestamps for sorting in the UI
      return state.orders.map(order => {
        // eslint-disable-next-line camelcase
        let { name, created_at, updated_at } = order
        return { name, created_at, updated_at }
      })
    },
    getMoneyTotal (state) {
      return state.moneyTotals
    },
    getPointsTotal (state) {
      return state.pointsTotals
    }
  }
}
