import Vue from 'vue'
import { ActionTree } from 'vuex'
import ProductState from '@vue-storefront/core/modules/catalog/types/ProductState'
import {
  setCustomTicketOptionsAsync,
  configureProductAsync,
  calculateTaxes,
  doPlatformPricesSync } from '../../helpers'
import * as types from '@vue-storefront/core/modules/catalog/store/product/mutation-types'
import RootState from '@vue-storefront/core/types/RootState'
import { entityKeyName } from '@vue-storefront/core/store/lib/entities'
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'
import { Logger } from '@vue-storefront/core/lib/logger'
import { isOnline, quickSearchByQuery } from '../../queries/search/search'
import { isServer } from '@vue-storefront/core/helpers'
import rootStore from '@vue-storefront/core/store'
import omit from 'lodash-es/omit'
import config from 'config'
import union from 'lodash-es/union'
import chunk from 'lodash-es/chunk'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { TaskQueue } from '@vue-storefront/core/lib/sync'

const actions: ActionTree<ProductState, RootState> = {
  async syncPlatformPricesOver (context, { skus }) {
    let output = null
    const storeView = currentStoreView()

    let chunks = chunk(skus, 100)
    let iterations = chunks.length

    for (const skuList of chunks) {
      let isLast = !--iterations

      let response = await TaskQueue.execute({ url: config.products.endpoint + '/render-list?skus=' + encodeURIComponent(skuList.join(',')) + '&currencyCode=' + encodeURIComponent(storeView.i18n.currencyCode) + '&storeId=' + encodeURIComponent(storeView.storeId), // sync the cart
        payload: {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors'
        },
        callback_event: isLast ? 'prices-after-sync' : null
      })

      if (!output) {
        output = response.result
      } else {
        output.items = union(output.items, response.result.items)
      }
    }

    return output
  },
  /**
   * Assign the custom options object to the current product
   */
  setTicketOptions (context, { customOptions, product }) {
    if (customOptions) { // TODO: this causes some kind of recurrency error
      context.commit(types.CATALOG_SET_PRODUCT_CURRENT, Object.assign({}, product, { product_option: setCustomTicketOptionsAsync(context, { product: context.state.current, customOptions: customOptions }) }))
    }
  },

  /**
   * Search ElasticSearch catalog of products using simple text query
   * Use bodybuilder to build the query, aggregations etc: http://bodybuilder.js.org/
   * @param {Object} query is the object of searchQuery class
   * @param {Int} start start index
   * @param {Int} size page size
   * @return {Promise}
   */
  list (context, { query, start = 0, size = 50, entityType = 'product', sort = '', cacheByKey = 'sku', prefetchGroupProducts = !isServer, updateState = false, meta = {}, excludeFields = null, includeFields = null, configuration = null, append = false, populateRequestCacheTags = true }) {
    let isCacheable = (includeFields === null && excludeFields === null)
    if (isCacheable) {
      Logger.debug('Entity cache is enabled for productList')()
    } else {
      Logger.debug('Entity cache is disabled for productList')()
    }

    if (config.entities.optimize) {
      if (excludeFields === null) { // if not set explicitly we do optimize the amount of data by using some default field list; this is cacheable
        excludeFields = config.entities.product.excludeFields
      }
      if (includeFields === null) { // if not set explicitly we do optimize the amount of data by using some default field list; this is cacheable
        includeFields = config.entities.product.includeFields
      }
    }
    return quickSearchByQuery({ query, start, size, entityType, sort, excludeFields, includeFields }).then((resp) => {
      if (resp.items && resp.items.length) { // preconfigure products; eg: after filters
        for (let product of resp.items) {
          if (populateRequestCacheTags && Vue.prototype.$ssrRequestContext) {
            Vue.prototype.$ssrRequestContext.output.cacheTags.add(`P${product.id}`)
          }
          product.errors = {} // this is an object to store validation result for custom options and others
          product.info = {}
          if (!product.qty) {
            product.qty = product.stock ? product.stock.qty_increments : 1
          }
          if (!product.parentSku) {
            product.parentSku = product.sku
          }
          if (config.products.setFirstVarianAsDefaultInURL && product.hasOwnProperty('configurable_children') && product.configurable_children.length > 0) {
            product.sku = product.configurable_children[0].sku
          }
          if (configuration) {
            // let selectedVariant = configureProductAsync(context, { product: product, configuration: configuration, selectDefaultVariant: false })
            // Object.assign(product, omit(selectedVariant, ['visibility']))
          }
          if (product.url_path) {
            rootStore.dispatch('url/registerMapping', {
              url: product.url_path,
              routeData: {
                params: {
                  'parentSku': product.parentSku,
                  'slug': product.slug
                },
                'name': product.type_id + '-product'
              }
            }, { root: true })
          }
        }
      }
      return calculateTaxes(resp.items, context).then((updatedProducts) => {
        // handle cache
        const cache = Vue.prototype.$db.elasticCacheCollection
        for (let prod of resp.items) { // we store each product separately in cache to have offline access to products/single method
          if (prod.configurable_children) {
            for (let configurableChild of prod.configurable_children) {
              if (configurableChild.custom_attributes) {
                for (let opt of configurableChild.custom_attributes) {
                  configurableChild[opt.attribute_code] = opt.value
                }
              }
            }
          }
          if (!prod[cacheByKey]) {
            cacheByKey = 'id'
          }
          const cacheKey = entityKeyName(cacheByKey, prod[(cacheByKey === 'sku' && prod['parentSku']) ? 'parentSku' : cacheByKey]) // to avoid caching products by configurable_children.sku
          if (isCacheable) { // store cache only for full loads
            cache.setItem(cacheKey, prod)
              .catch((err) => {
                Logger.error('Cannot store cache for ' + cacheKey, err)()
              })
          }
          if ((prod.type_id === 'grouped' || prod.type_id === 'bundle') && prefetchGroupProducts && !isServer) {
            // updateState = true
            context.dispatch('setupAssociated', { product: prod })
          }
        }
        // commit update products list mutation
        if (updateState) {
          context.commit(types.CATALOG_UPD_PRODUCTS, { products: resp, append: append })
        }
        Vue.prototype.$bus.$emit('product-after-list', { query: query, start: start, size: size, sort: sort, entityType: entityType, meta: meta, result: resp })
        return resp
      })
    })
  },

  /**
   * Search ElasticSearch catalog of products using simple text query
   * Use bodybuilder to build the query, aggregations etc: http://bodybuilder.js.org/
   * optimized for fast return of large amounts of products (mainly by skipping cache tagging)
   * author: cgardner@luxbp.com
   * @param {Object} query is the object of searchQuery class
   * @param {Int} start start index
   * @param {Int} size page size
   * @return {Promise}
   */
  quicklist (context, { query, start = 0, size = 50, entityType = 'product', sort = '', cacheByKey = 'sku', prefetchGroupProducts = !isServer, updateState = false, meta = {}, excludeFields = null, includeFields = null, configuration = null, append = false, populateRequestCacheTags = true }) {
    let isCacheable = (includeFields === null && excludeFields === null)
    if (isCacheable) {
      Logger.debug('Entity cache is enabled for productList')()
    } else {
      Logger.debug('Entity cache is disabled for productList')()
    }

    if (config.entities.optimize) {
      if (excludeFields === null) { // if not set explicitly we do optimize the amount of data by using some default field list; this is cacheable
        excludeFields = config.entities.product.excludeFields
      }
      if (includeFields === null) { // if not set explicitly we do optimize the amount of data by using some default field list; this is cacheable
        includeFields = config.entities.product.includeFields
      }
    }
    return quickSearchByQuery({ query, start, size, entityType, sort, excludeFields, includeFields }).then((resp) => {
      return calculateTaxes(resp.items, context).then((updatedProducts) => {
        // handle cache
        const cache = Vue.prototype.$db.elasticCacheCollection
        for (let prod of resp.items) { // we store each product separately in cache to have offline access to products/single method
          if (prod.configurable_children) {
            for (let configurableChild of prod.configurable_children) {
              if (configurableChild.custom_attributes) {
                for (let opt of configurableChild.custom_attributes) {
                  configurableChild[opt.attribute_code] = opt.value
                }
              }
            }
          }
          if (!prod[cacheByKey]) {
            cacheByKey = 'id'
          }
          const cacheKey = entityKeyName(cacheByKey, prod[(cacheByKey === 'sku' && prod['parentSku']) ? 'parentSku' : cacheByKey]) // to avoid caching products by configurable_children.sku
          if (isCacheable) { // store cache only for full loads
            cache.setItem(cacheKey, prod)
              .catch((err) => {
                Logger.error('Cannot store cache for ' + cacheKey, err)()
              })
          }
          if ((prod.type_id === 'grouped' || prod.type_id === 'bundle') && prefetchGroupProducts && !isServer) {
            context.dispatch('setupAssociated', { product: prod })
          }
        }
        // commit update products list mutation
        if (updateState) {
          context.commit(types.CATALOG_UPD_PRODUCTS, { products: resp, append: append })
        }
        Vue.prototype.$bus.$emit('product-after-list', { query: query, start: start, size: size, sort: sort, entityType: entityType, meta: meta, result: resp })
        return resp
      })
    })
  },
  /**
   * Search products by specific field
   * @param {Object} options
   */
  async single (context, { options, setCurrentProduct = true, selectDefaultVariant = true, assignDefaultVariant = false, key = 'sku', skipCache = false }) {
    if (!options[key]) {
      throw Error('Please provide the search key ' + key + ' for product/single action!')
    }
    const cacheKey = entityKeyName(key, options[key])

    return new Promise((resolve, reject) => {
      const benchmarkTime = new Date()
      const cache = Vue.prototype.$db.elasticCacheCollection

      const setupProduct = (prod) => {
        // set product quantity to 1
        if (!prod.qty) {
          prod.qty = 1
        }
        // set original product
        if (setCurrentProduct) {
          context.dispatch('setOriginal', prod)
        }
        // check is prod has configurable children
        const hasConfigurableChildren = prod && prod.configurable_children && prod.configurable_children.length
        if (prod.type_id === 'simple' && hasConfigurableChildren) { // workaround for #983
          prod = omit(prod, ['configurable_children', 'configurable_options'])
        }

        // set current product - configurable or not
        if (prod.type_id === 'configurable' && hasConfigurableChildren) {
          // set first available configuration
          // todo: probably a good idea is to change this [0] to specific id
          const selectedVariant = configureProductAsync(context, { product: prod, configuration: { sku: options.childSku }, selectDefaultVariant: selectDefaultVariant, setProductErorrs: true })
          if (selectedVariant && assignDefaultVariant) {
            prod = Object.assign(prod, selectedVariant)
          }
        } else if (!skipCache || (prod.type_id === 'simple' || prod.type_id === 'downloadable')) {
          if (setCurrentProduct) context.dispatch('setCurrent', prod)
        }

        return prod
      }

      const syncProducts = () => {
        let searchQuery = new SearchQuery()
        searchQuery = searchQuery.applyFilter({key: key, value: {'eq': options[key]}})

        return context.dispatch('list', { // product list syncs the platform price on it's own
          query: searchQuery,
          prefetchGroupProducts: false,
          updateState: false
        }).then((res) => {
          if (res && res.items && res.items.length) {
            let prd = res.items[0]
            const _returnProductNoCacheHelper = (subresults) => {
              Vue.prototype.$bus.$emitFilter('product-after-single', { key: key, options: options, product: prd })
              resolve(setupProduct(prd))
            }
            if (setCurrentProduct || selectDefaultVariant) {
              const subConfigPromises = []
              if (prd.type_id === 'bundle') {
                subConfigPromises.push(context.dispatch('configureBundleAsync', prd))
              }

              if (prd.type_id === 'grouped') {
                subConfigPromises.push(context.dispatch('configureGroupedAsync', prd))
              }
              subConfigPromises.push(context.dispatch('setupVariants', { product: prd }))
              Promise.all(subConfigPromises).then(_returnProductNoCacheHelper)
            } else {
              _returnProductNoCacheHelper(null)
            }
          } else {
            reject(new Error('Product query returned empty result'))
          }
        })
      }

      const getProductFromCache = () => {
        cache.getItem(cacheKey, (err, res) => {
          // report errors
          if (!skipCache && err) {
            Logger.error(err, 'product')()
          }

          if (res !== null) {
            Logger.debug('Product:single - result from localForage (for ' + cacheKey + '),  ms=' + (new Date().getTime() - benchmarkTime.getTime()), 'product')()
            const _returnProductFromCacheHelper = (subresults) => {
              const cachedProduct = setupProduct(res)
              if (config.products.alwaysSyncPlatformPricesOver) {
                doPlatformPricesSync([cachedProduct]).then((products) => {
                  Vue.prototype.$bus.$emitFilter('product-after-single', { key: key, options: options, product: products[0] })
                  resolve(products[0])
                })
                if (!config.products.waitForPlatformSync) {
                  Vue.prototype.$bus.$emitFilter('product-after-single', { key: key, options: options, product: cachedProduct })
                  resolve(cachedProduct)
                }
              } else {
                Vue.prototype.$bus.$emitFilter('product-after-single', { key: key, options: options, product: cachedProduct })
                resolve(cachedProduct)
              }
            }
            if (setCurrentProduct || selectDefaultVariant) {
              const subConfigPromises = []
              subConfigPromises.push(context.dispatch('setupVariants', { product: res }))
              if (res.type_id === 'bundle') {
                subConfigPromises.push(context.dispatch('configureBundleAsync', res))
              }
              if (res.type_id === 'grouped') {
                subConfigPromises.push(context.dispatch('configureGroupedAsync', res))
              }
              Promise.all(subConfigPromises).then(_returnProductFromCacheHelper)
            } else {
              _returnProductFromCacheHelper(null)
            }
          } else {
            syncProducts()
          }
        })
      }

      if (!skipCache) {
        getProductFromCache()
      } else {
        if (!isOnline()) {
          skipCache = false;
        }

        syncProducts()
      }
    })
  },
  /**
   * Load the product data
   */
  fetch (context, { parentSku, childSku = null }) {
    // pass both id and sku to render a product
    const productSingleOptions = {
      sku: parentSku,
      childSku: childSku
    }
    return context.dispatch('single', { options: productSingleOptions }).then((product) => {
      if (product.status >= 2) {
        throw new Error(`Product query returned empty result product status = ${product.status}`)
      }
      if (product.visibility === 1) { // not visible individually (https://magento.stackexchange.com/questions/171584/magento-2-table-name-for-product-visibility)
        throw new Error(`Product query returned empty result product visibility = ${product.visibility}`)
      }

      let subloaders = []

      if (product) {
        let productKeys = Object.keys(product)

        for (let child of (product.configurable_children || [])) {
          productKeys = union(productKeys, Object.keys(child))
        }
        const productFields = productKeys.filter(fieldName => {
          return config.entities.product.standardSystemFields.indexOf(fieldName) < 0 // don't load metadata info for standard fields
        })
        const attributesPromise = context.dispatch('attribute/list', { // load attributes to be shown on the product details - the request is now async
          filterValues: config.entities.product.useDynamicAttributeLoader ? productFields : null,
          only_visible: config.entities.product.useDynamicAttributeLoader === true,
          only_user_defined: true,
          includeFields: config.entities.optimize ? config.entities.attribute.includeFields : null
        }, { root: true }) // TODO: it might be refactored to kind of: `await context.dispatch('attributes/list) - or using new Promise() .. to wait for attributes to be loaded before executing the next action. However it may decrease the performance - so for now we're just waiting with the breadcrumbs

        if (isServer) {
          subloaders.push(context.dispatch('setupBreadcrumbs', { product: product }))
          subloaders.push(context.dispatch('filterUnavailableVariants', { product: product }))
        } else {
          attributesPromise.then(() => context.dispatch('setupBreadcrumbs', { product: product })) // if this is client's side request postpone breadcrumbs setup till attributes are loaded to avoid too-early breadcrumb switch #2469
          context.dispatch('filterUnavailableVariants', { product: product }) // exec async
        }
        subloaders.push(attributesPromise)

        // subloaders.push(context.dispatch('setupVariants', { product: product })) -- moved to "product/single"
        /* if (product.type_id === 'grouped' || product.type_id === 'bundle') { -- moved to "product/single"
          subloaders.push(context.dispatch('setupAssociated', { product: product }).then((subloaderresults) => {
            context.dispatch('setCurrent', product) // because setup Associated can modify the product price we need to update the current product
          }))
        } */

        context.dispatch('setProductGallery', { product: product })

        if (config.products.preventConfigurableChildrenDirectAccess) {
          subloaders.push(context.dispatch('checkConfigurableParent', { product: product }))
        }
      } else { // error or redirect

      }
      return subloaders
    })
  }
}

export default actions
