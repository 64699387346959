import Vue from 'vue'
import { ActionTree } from 'vuex'
import rootStore from '@vue-storefront/core/store'
import * as types from './mutation-types'
import { isServer } from '@vue-storefront/core/helpers'
import { adjustMultistoreApiUrl } from '@vue-storefront/core/lib/multistore'
import RootState from '@vue-storefront/core/types/RootState'
import UserState from '../types/UserState'
import { processURLAddress } from '@vue-storefront/core/helpers'
import find from 'lodash-es/find'
import debounce from 'lodash-es/debounce'
import config from 'config'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import { Logger } from '@vue-storefront/core/lib/logger'

const loadLoyaltyData = debounce(function () {
  rootStore.dispatch('loyalty/loyaltyCustomer')
  rootStore.dispatch('loyalty/loyaltyDashboard')
}, 1000, { leading: true, trailing: false })

const debounceCartAuthorize = debounce(() => {
  rootStore.dispatch('cart/authorize')
}, 1000, { leading: true, trailing: false });

const actions: ActionTree<UserState, RootState> = {
  startSession: debounce(async (context) => {
    if (isServer || context.getters.isLocalDataLoaded) return
    const cache = Vue.prototype.$db.usersCollection

    const user = await cache.getItem(`current-user`)

    if (user) {
      context.commit(types.USER_INFO_LOADED, user)
    }

    context.commit(types.USER_START_SESSION)
    context.commit(types.USER_LOCAL_DATA_LOADED, true)

    cache.getItem('current-token', (err, res) => {
      if (err) {
        Logger.error(err, 'user')()
        return
      }

      if (res) {
        context.commit(types.USER_TOKEN_CHANGED, { newToken: res })
        context.dispatch('sessionAfterAuthorized')

        if (config.usePriceTiers) {
          cache.getItem('current-user', (err, userData) => {
            if (err) {
              Logger.error(err, 'user')()
              return
            }

            if (userData) {
              context.dispatch('setUserGroup', userData)
            }
          })
        }
      } else {
        Vue.prototype.$bus.$emit('session-after-nonauthorized')
      }
      Vue.prototype.$bus.$emit('session-after-started')
    })
  }, 1000, { leading: true, trailing: false }),
  /**
   * Update user groupToken and groupId in state
   * @param context
   * @param userData
   */
  setUserGroup (context, userData) {
    if (config.usePriceTiers) {
      if (userData.groupToken) {
        context.commit(types.USER_GROUP_TOKEN_CHANGED, userData.groupToken)
      }

      if (userData.group_id) {
        context.commit(types.USER_GROUP_CHANGED, userData.group_id)
      }
    } else {
      context.commit(types.USER_GROUP_TOKEN_CHANGED, '')
      context.commit(types.USER_GROUP_CHANGED, null)
    }
    context.dispatch('setRewardId', userData)
  },

  /**
   * Update user rewardId in state
   * @param context
   * @param userData
   */

  setRewardId (context, userData) {
    let rewardId = null

    if (userData.custom_attributes) {
      rewardId = find(userData.custom_attributes, { 'attribute_code': 'reward_id' })
    }

    if (rewardId) {
      context.commit(types.USER_REWARD_ID_CHANGED, rewardId.value)
    }
  },

  /**
   * Load current user profile
   */
  async me (context, { refresh = true, useCache = true } = {}) {
    return new Promise((resolve, reject) => {
      if (!context.state.token) {
        Logger.warn('No User token, user unauthorized', 'user')()
        return resolve(null)
      }
      const cache = Vue.prototype.$db.usersCollection
      let resolvedFromCache = false

      if (useCache === true) { // after login for example we shouldn't use cache to be sure we're loading currently logged in user
        cache.getItem('current-user', (err, res) => {
          if (err) {
            Logger.error(err, 'user')()
            return
          }

          if (res) {
            context.commit(types.USER_INFO_LOADED, res)
            context.dispatch('setUserGroup', res)
            Vue.prototype.$bus.$emit('user-after-loggedin', res)
            // rootStore.dispatch('cart/authorize')
            debounceCartAuthorize()

            resolve(res)
            resolvedFromCache = true
            Logger.log('Current user served from cache', 'user')()
          }
        })
      }

      if (refresh) {
        TaskQueue.execute({ url: config.users.me_endpoint,
          payload: { method: 'GET',
            mode: 'cors',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            }
          }
        })
          .then((resp: any) => {
            if (resp.resultCode === 200) {
              context.commit(types.USER_INFO_LOADED, resp.result) // this also stores the current user to localForage
              context.dispatch('setUserGroup', resp.result)
            }
            if (!resolvedFromCache && resp.resultCode === 200) {
              Vue.prototype.$bus.$emit('user-after-loggedin', resp.result)
              // rootStore.dispatch('cart/authorize')
              debounceCartAuthorize()
              resolve(resp)
            } else {
              resolve(null)
            }
            return resp
          })
          .then((result: any) => {
            if (!isServer) loadLoyaltyData()

            return result
          })
      } else {
        if (!resolvedFromCache) {
          resolve(null)
        }
      }
    })
  },

  /**
   * Login user and return user profile and current token
   */
  register (context, { email, firstname, lastname, password, customAttributes }) {
    let url = config.users.create_endpoint

    if (config.storeViews.multistore) {
      url = adjustMultistoreApiUrl(url)
    }
    return fetch(url, { method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ customer: { email: email, firstname: firstname, lastname: lastname, customAttributes: customAttributes }, password: password })
    }).then(resp => { return resp.json() })
      .then((resp) => {
        if (resp.code === 200) {
          context.dispatch('login', { username: email, password: password }).then(result => { // login user
          })
        }
        return resp
      })
  },

  /**
   * Create new user password
   */
  createPassword (context, { email, resetToken, newPassword }): Promise<Response> {
    let url = config.users.createPassword_endpoint
    if (config.storeViews.multistore) {
      url = adjustMultistoreApiUrl(url)
    }

    return new Promise((resolve, reject) => {
      fetch(processURLAddress(url), { method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email,
          resetToken,
          newPassword
        })
      }).then(resp => {
        resp.json().then(json => {
          if (resp.ok) {
            rootStore.dispatch('user/login', {
              username: email,
              password: newPassword
            })

            resolve(json)
          } else {
            reject(json)
          }
        })
      })
    })
  },

  /**
   * Create new user password by storeId
   */
  createPasswordByStoreId (context, { email, resetToken, newPassword, websiteId, storeId }): Promise<Response> {
    let url = config.users.createPassword_endpoint
    if (config.storeViews.multistore) {
      url = adjustMultistoreApiUrl(url)
    }

    return new Promise((resolve, reject) => {
      fetch(processURLAddress(url), { method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email,
          resetToken,
          newPassword,
          websiteId,
          storeId
        })
      }).then(resp => {
        resp.json().then(json => {
          if (resp.ok) {
            rootStore.dispatch('user/login', {
              username: email,
              password: newPassword
            })

            resolve(json)
          } else {
            reject(json)
          }
        })
      })
    })
  },
  /**
   * Send password reset link for specific e-mail
   */
  resetPasswordByStoreId (context, { email, websiteId, storeId }) {
    return TaskQueue.execute({ url: config.users.resetPassword_endpoint,
      payload: {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          website_id: websiteId,
          store_id: storeId
        })
      }
    })
  },
  /**
   * Load user's orders history
   */
  getOrdersHistory (context, { refresh = true, useCache = true, pageSize = 25, currentPage = 1 }) {
    // TODO: Make it as an extension from users module
    return new Promise((resolve, reject) => {
      if (!context.state.token) {
        Logger.debug('No User token, user unathorized', 'user')()
        return resolve(null)
      }
      const cache = Vue.prototype.$db.ordersHistoryCollection
      let resolvedFromCache = false

      if (useCache === true) { // after login for example we shouldn't use cache to be sure we're loading currently logged in user
        cache.getItem('orders-history', (err, res) => {
          if (err) {
            Logger.error(err, 'user')()
            return
          }

          if (res) {
            context.commit(types.USER_ORDERS_HISTORY_LOADED, res)
            Vue.prototype.$bus.$emit('user-after-loaded-orders', res)

            resolve(res)
            resolvedFromCache = true
            Logger.log('Current user order history served from cache', 'user')()
          }
        })
      }

      if (refresh) {
        return TaskQueue.execute({ url: config.users.history_endpoint.concat(`&pageSize=${pageSize}`).concat(`&currentPage=${currentPage}`),
          payload: { method: 'GET',
            mode: 'cors',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            }
          }
        }).then((resp: any) => {
          if (resp.code === 200) {
            context.commit(types.USER_ORDERS_HISTORY_LOADED, resp.result) // this also stores the current user to localForage
            Vue.prototype.$bus.$emit('user-after-loaded-orders', resp.result)
          }
          if (!resolvedFromCache) {
            resolve(resp.code === 200 ? resp : null)
          }
          return resp
        })
      } else {
        if (!resolvedFromCache) {
          resolve(null)
        }
      }
    })
  },
}

export default actions
