import NotificationItem from '@vue-storefront/core/modules/notification/types/NotificationItem'
import { datadogRum } from '@datadog/browser-rum'
import { Module } from 'vuex'
import NotificationState from '@vue-storefront/core/modules/notification/types/NotificationState'

export const module: Module<NotificationState, any> = {
  actions: {
    spawnNotification ({ commit, state, dispatch }, notification: NotificationItem) {
      if (state.notifications.length > 0 &&
        state.notifications[state.notifications.length - 1].message === notification.message
      ) {
        return
      }

      if (notification.type === 'error' || notification.type === 'warn') {
        datadogRum.addError(new Error(notification.message))
      }

      commit('add', notification)
      if (!notification.hasNoTimeout) {
        setTimeout(() => {
          dispatch('removeNotification')
        }, notification.timeToLive || 5000)
      }
    }
  }
}
