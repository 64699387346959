import {ActionTree} from 'vuex';
import config from 'config'
import fetch from 'isomorphic-fetch'
import BrandsState from '../types/BrandsState';
import {isServer, processURLAddress} from '@vue-storefront/core/helpers'
import * as types from './mutation-types'
import { Logger } from '@vue-storefront/core/lib/logger'
import qs from 'qs'
import Vue from 'vue'
import rootStore from '@vue-storefront/core/store'
import i18n from '@vue-storefront/i18n'

export const actions: ActionTree<BrandsState, any> = {
  getBrands({ commit, state }, query) {
    if (!config.brands) {
      return
    }
    const apiUrl = `${processURLAddress(config.brands.endpoint.getBrands)}?${qs.stringify(query)}`

    return new Promise<Response>((resolve, reject) => {
      fetch(apiUrl, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Accept': 'application/json'
        }
      }).then(resp => resp.json()).then(json => {
        if (json.result) commit(types.ALL_BRANDS, json.result.items)
        resolve(json.result.items)
      }).catch(err => {
        reject(err)
        Logger.error(err)
      })
    })
  },
  getBrandAccess (context, { data, referer }) {
    if (!config.brands || !config.brands.endpoint.getAccess || !referer) return

    const apiUrl = processURLAddress(config.brands.endpoint.getAccess)

    return new Promise<Response>((resolve, reject) => {
      fetch(apiUrl, {
        method: 'POST',
        credentials: 'omit',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Referer': referer
        },
        body: JSON.stringify(data)
      }).then(resp => resp.json()).then(json => {
        if (json.status) {
          rootStore.dispatch('notification/spawnNotification', {
            type: 'warning',
            message: i18n.t('Thank you! Your request has been sent.'),
            action1: { label: i18n.t('OK') },
            timeToLive: 10000
          })
        } else {
          rootStore.dispatch('notification/spawnNotification', {
            type: 'error',
            message: i18n.t('Please try again later'),
            action1: { label: i18n.t('OK') }
          })
        }
        resolve(json)
      }).catch(err => {
        reject(err)
        Logger.error(err)
      })
    })
  },
  async syncBrands (context) {
    if (isServer) return

    const cache = Vue.prototype.$db.brandsCollection
    const brands = await cache.getItem('brands')

    if (brands) context.commit(types.ALL_BRANDS, brands)
  }
}
