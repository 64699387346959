import Vue from 'vue'
import omit from 'lodash-es/omit'
import { ActionTree } from 'vuex'
import * as types from './mutation-types'
import config from 'config'
import fetch from 'isomorphic-fetch'
import {isServer, processURLAddress} from '@vue-storefront/core/helpers'
import { Logger } from '@vue-storefront/core/lib/logger'
import rootStore from '@vue-storefront/core/store'
import LoyaltyProgramState from '../types/LoyaltyProgramState'

import { cacheStorage } from '../'

export const actions: ActionTree<LoyaltyProgramState, any> = {
  loyaltyTiers ({ commit }) {
    const apiUrl = `${processURLAddress(config.loyaltyProgram.endpoint)}/${config.loyaltyProgram.tiers}`
    return new Promise<Response>((resolve, reject) => {
      fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      }).then(resp => resp.json()).then(json => {
        if (json.data && json.data.length) {
          commit(types.LOYALTY_TIERS, json.data)
        }
        resolve(json.data)
      }).catch(err => {
        reject(err)
        Logger.error(err)
      })
    })
  },
  loyaltyCustomer (context) {
    let rewardId = rootStore.getters['user/getRewardId']
    if (!rewardId) {
      return new Promise((resolve, reject) => {
        resolve({})
      })
    }
    const apiUrl = `${processURLAddress(config.loyaltyProgram.endpoint)}/${config.loyaltyProgram.customer.replace('{{customer}}', rewardId)}`

    return new Promise<Response>((resolve, reject) => {
      fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      }).then(resp => resp.json()).then(json => {
        if (json.data) context.commit(types.LOYALTY_CUSTOMER, json.data)
        resolve(json.data)
      }).catch(err => {
        reject(err)
        Logger.error(err)
      })
    })
  },
  loyaltyDashboard (context) {
    let rewardId = rootStore.getters['user/getRewardId']
    if (!rewardId) {
      return new Promise((resolve, reject) => {
        resolve({})
      })
    }

    const apiUrl = `${processURLAddress(config.loyaltyProgram.endpoint)}/${config.loyaltyProgram.dashboard.replace('{{customer}}', rewardId)}`

    return new Promise<Response>((resolve, reject) => {
      fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      }).then(resp => resp.json()).then(json => {
        if (json.data) context.commit(types.LOYALTY_DASHBOARD, json.data)
        resolve(json.data)
      }).catch(err => {
        reject(err)
        Logger.error(err)
      })
    })
  },
  loyaltyEstimatePoints (context, { items }) {
    const apiUrl = `${processURLAddress(config.loyaltyProgram.endpoint)}/${config.loyaltyProgram.estimate}`
    let rewardId = rootStore.getters['user/getRewardId']
    if (!rewardId) {
      return new Promise((resolve, reject) => {
        resolve({})
      })
    }

    return new Promise<Response>((resolve, reject) => {
      fetch(apiUrl, {
        method: 'POST',
        credentials: 'omit',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          customer_id: rewardId,
          items: items
        })
      }).then(resp => resp.json()).then(json => {
        resolve(json.data)
      }).catch(err => {
        reject(err)
        Logger.error(err)
      })
    })
  },
  async syncCustomer (context) {
    if (isServer) return

    const cache = Vue.prototype.$db.loyaltyProgramCollection
    const loyaltyCustomer = await cache.getItem('loyalty-customer')

    if (loyaltyCustomer) context.commit(types.LOYALTY_CUSTOMER, loyaltyCustomer)
  },
  async syncDashboard (context) {
    if (isServer) return

    const cache = Vue.prototype.$db.loyaltyProgramCollection
    const loyaltyDashboard = await cache.getItem('loyalty-dashboard')

    if (loyaltyDashboard) context.commit(types.LOYALTY_DASHBOARD, loyaltyDashboard)
  },
  async loadCart ({ commit }) {
    const items = await cacheStorage.getItem('cart')
    if (items) {
      commit(types.LOYALTY_CART_SET, items)
      return items
    } else {
      return []
    }
  },
  clearCart ({ commit }) {
    cacheStorage.removeItem('cart')
    commit(types.LOYALTY_CART_SET, [])
  },
  addRewardItem ({ dispatch }, { productToAdd }) {
    let productsToAdd = []
    if (productToAdd.type_id === 'grouped') { // TODO: add bundle support
      productsToAdd = productToAdd.product_links.filter((pl) => { return pl.link_type === 'associated' }).map((pl) => { return pl.product })
    } else {
      productsToAdd.push(productToAdd)
    }
    return dispatch('addRewardItems', { productsToAdd: productsToAdd })
  },
  addRewardItems ({ state, commit }, { productsToAdd, useCache = true }) {
    for (let product of productsToAdd) {
      if (typeof product === 'undefined' || product === null) continue
      if (product.qty && typeof product.qty !== 'number') product.qty = parseInt(product.qty)
      if (config.entities.optimize && config.entities.optimizeShoppingCart) {
        product = omit(product, ['configurable_children', 'configurable_options', 'media_gallery', 'description', 'category', 'category_ids', 'product_links', 'description'])
      }
      if (product.errors !== null && typeof product.errors !== 'undefined') {
        let productCanBeAdded = true
        for (let errKey in product.errors) {
          if (product.errors[errKey]) {
            productCanBeAdded = false
          }
        }
        if (!productCanBeAdded) {
          continue
        }
      }
      commit(types.LOYALTY_CART_ADD_ITEM, { product })
      if (useCache) cacheStorage.setItem('cart', state.cartItems)
    }
  },
  removeRewardItem ({ state, commit }, { product, removeByParentSku, useCache = true  }) {
    commit(types.LOYALTY_CART_DEL_ITEM, { product, removeByParentSku })
    if (useCache) cacheStorage.setItem('cart', state.cartItems)
  },
  updateRewardQuantity ({ state, commit }, { product, qty, useCache = true }) {
    commit(types.LOYALTY_CART_UPD_ITEM, { product, qty })
    if (useCache) cacheStorage.setItem('cart', state.cartItems)
  },
  getRewardCart() {

  }

}
