const KEY = 'ui'
const store = {
  state: {
    mobilemenu: false,
    footermenu: false,
    myaccountmenu: false,
    forceOpen: false
  },
  mutations: {
    setFooterMenu (state, action) {
      state.footermenu = action === true
    },
    setMobileMenu (state, action) {
      state.mobilemenu = action === true
    },
    setMyAccountMenu (state, action) {
      state.myaccountmenu = action === true
    },
    setMicrocart (state, action) {
      state.microcart = action === true
    },
    setForceOpen (state, action) {
      state.forceOpen = action === true
    }
  }
}

export const UiExtend = {
  key: KEY,
  store: { modules: [{ key: KEY, module: store }] }
}
