import App from './App.vue'
import routes from './router'
import Vue from 'vue'
import VueProgressBar from 'vue-progressbar'
import './components/storyblok'
import * as themeFilters from './filters'
import { RouterManager } from '@vue-storefront/core/lib/router-manager'
import { localizedRoute } from '@vue-storefront/core/lib/multistore'
import {isServer, once} from '@vue-storefront/core/helpers'
// eslint-disable-next-line import/extensions
import { version } from './package.json'
import { bootLoginAsCustomer } from 'theme/modules/login-as-customer/hooks/afterRegistration'

once('__VUE_EXTEND_THEME_FILTERS__', () => {
  Object.keys(themeFilters).forEach(key => {
    Vue.filter(key, themeFilters[key])
  })
})

once('__VUE_EXTEND_DROPPOINT_VPB__', () => {
  Vue.use(VueProgressBar, {
    color: '#00e1ba',
    failedColor: '#e81212'
  })
})

const themeEntry = App

async function initTheme (app, router, store, config, ssrContext) {
  if (!isServer) {
    await bootLoginAsCustomer({
      Vue,
      config,
      store,
      isServer
    })
  }

  const allStoreRoutes = [...routes]

  if (config.storeViews.mapStoreUrlsFor.length > 0 && config.storeViews.multistore === true) {
    for (const storeCode of config.storeViews.mapStoreUrlsFor) {
      if (storeCode && (config.defaultStoreCode !== storeCode)) {
        for (const route of routes) {
          const localRoute = localizedRoute(Object.assign({}, route), storeCode)

          allStoreRoutes.push(localRoute)
        }
      }
    }
  }

  store.state.version = version

  /* eslint-disable-next-line no-console */
  console.log('Current Version: ' + version)

  RouterManager.addRoutes(allStoreRoutes, router)
}

export {
  themeEntry,
  initTheme
}
