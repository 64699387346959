import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import CartState from '@vue-storefront/core/modules/cart/types/CartState'
import general from './cart/general'
import override from './cart/override'
import rewards from './cart/rewards'

const actions: ActionTree<CartState, RootState> = {
  ...general,
  ...override,
  ...rewards
}

export default actions
