import { BroadcastChannel } from 'broadcast-channel';
import i18n from '@vue-storefront/i18n'

declare global {
  interface Window { broadcast: any; }
}

export async function afterRegistration ({ Vue, config, store, isServer }) {
  if (!isServer) {
    window.broadcast = window.broadcast || [];
    window.broadcast['reload'] = new BroadcastChannel('reload-channel');
    window.broadcast['reload'].onmessage = (e) => {
      if (e === 'execute') {
        setTimeout(() => {
          reloadAction();
          console.log('Reload triggered...')
        }, 100);
      }
    };

    const reloadAction = () => {
      const currentParams = new URLSearchParams(window.location.search);

      if (window.location.href.includes('#')) {
        return;
      }
      if (currentParams.get('reloaded')) {
        return;
      }


      let params = [
        "reloaded=1",
      ];

      window.location.href =
        window.location.href +
        params.length ? ('?' + params.join('&')) : '';
    };

    const clearServiceWorker = async () => {
      window.navigator.serviceWorker.getRegistrations().then(async registrations => {
        await Promise.all(registrations.map(async (registration) => {
          await registration.unregister();
        })).then(() => {
          reloadAction();
          if (window.broadcast['reload']) {
            window.broadcast['reload'].postMessage('execute');
          }
        })
      })
    };

    const sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };

    // clear cache on logout
    Vue.prototype.$bus.$on('user-after-logout', async () => {
      await sleep(300);
      store.dispatch('versioning/update', false)
      await clearServiceWorker();
    });

    Vue.prototype.$bus.$on('user-after-loggedin', async () => {
      // broadcast to all tabs to reload
      if (window.broadcast['reload']) {
         window.broadcast['reload'].postMessage('execute');
      }
    });


    // offline stability check
    let offlineCount = 0;

    window.addEventListener("offline", (event) => {
      if (offlineCount > 1) {
        store.dispatch('notification/spawnNotification', {
          type: 'warning',
          message: i18n.t('Your connection is unstable and may impact ordering.'),
          action1: { label: i18n.t('OK') },
          hasNoTimeout: true
        })

        offlineCount = 0
      } else {
        store.dispatch('notification/spawnNotification', {
          type: 'info',
          message: i18n.t('You are offline.'),
          action1: { label: i18n.t('OK') }
        })
      }

      offlineCount++;
    });

    window.addEventListener("online", (event) => {
      store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: i18n.t('You are back online.'),
        action1: { label: i18n.t('OK') }
      })
    });
  }
}
