import { module } from './store'
import { createModule } from '@vue-storefront/core/lib/module'
import { afterRegistration } from './hooks/afterRegistration'
import {initCacheStorage} from '@vue-storefront/core/helpers/initCacheStorage'

export const KEY = 'quickorder'

export const cacheStorage = initCacheStorage(KEY)

export const QuickOrder = createModule({
  key: KEY,
  store: { modules: [{ key: KEY, module }] },
  afterRegistration
})
