import * as localForage from 'localforage'
import UniversalStorage from '@vue-storefront/core/store/lib/storage'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import rootStore from '@vue-storefront/core/store'

export async function bootLoginAsCustomer ({ Vue, config, store, isServer }) {
  if (isServer) return

  // get params
  const params = new URLSearchParams(window.location.search)
  const sso = params.get('__sso')
  const sst = params.get('__sst')
  const cartToken = params.get('__ct')

  // return if all are not present
  if (!sso || !sst) return

  const storeView = currentStoreView()
  const dbNamePrefix = storeView.storeCode ? storeView.storeCode + '-' : ''

  // clear local storage
  window.localStorage.clear()

  // rebuild local storage with new tokens
  Vue.prototype.$db.cartsCollection = new UniversalStorage(localForage.createInstance({
    name: (config.storeViews.commonCache ? '' : dbNamePrefix) + 'shop',
    storeName: 'carts',
    driver: localForage[config.localForage.defaultDrivers['carts']]
  }))

  Vue.prototype.$db.usersCollection = new UniversalStorage(localForage.createInstance({
    name: (config.storeViews.commonCache ? '' : dbNamePrefix) + 'shop',
    storeName: 'user',
    driver: localForage[config.localForage.defaultDrivers['user']]
  }))

  if (cartToken) {
    await Vue.prototype.$db.cartsCollection.setItem('current-cart-token', cartToken)
  }

  await Vue.prototype.$db.usersCollection.setItem('current-token', sso)
  await Vue.prototype.$db.usersCollection.setItem('current-refresh-token', sst)

  Vue.prototype.$bus.$on('user-after-loggedin', () => {
    window.broadcast = window.broadcast || []
    if (window.broadcast['reload']) {
      window.broadcast['reload'].postMessage('execute')
    }
    // reload page after sessions start
    window.location.replace(window.location.origin)
  })

  await rootStore.dispatch('user/startSession')
}
