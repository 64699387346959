
import { beforeRegistration } from '../../../../../core/modules/checkout/hooks/beforeRegistration'
import { afterRegistration } from '../../../../../core/modules/checkout/hooks/afterRegistration'

const extendCheckoutVuex = {
  state: {
    personalDetails: {
      fax: '',
      company: ''
    },
    paymentDetails: {
      emailAddress: '',
      fax: '',
      createAccount: false
    },
    shippingDetails: {
      company: '',
      fax: ''
    },
    shippingMethod: {

    }
  },
  actions: {
    /*
    addFieldsToCheckoutAction ({ commit }) {
      commit('addFieldsToCheckout')
    }
    */
  },
  mutations: {
    /*
    Example: Add fields via mutations
    addFieldsToCheckout (state) {
      state.personalDetails = { ...state.personalDetails, newProp: 123 }
    }
    */
  }
}

export const checkoutExtend = {
  key: 'checkout',
  beforeRegistration,
  afterRegistration,
  store: { modules: [{ key: 'checkout', module: extendCheckoutVuex }] }
}
