import {cacheStorage, transactionKey} from '../'
import {cartCacheHandlerFactory} from '@vue-storefront/core/modules/cart/helpers/cartCacheHandler'

export function afterRegistration ({Vue, config, store, isServer}) {
  store.subscribe(cartCacheHandlerFactory(Vue))

  // initializing state refresh caching
  cacheStorage.setItem(transactionKey, Date.now())

  // force load
  let bootTime = parseInt(cacheStorage.getItem(transactionKey) || 0, 10)

  let bootInterval = setInterval(() => {
    let time = bootTime + 4000

    if (time > Date.now() && !store.state.cart.cartIsLoaded) {
      if (!isServer) store.dispatch('cart/load')
      clearInterval(bootInterval)
    }

    if (isServer) clearInterval(bootInterval)
  }, 1000)

  let cacheInterval = setInterval(() => {
    // get last transaction processed time from cache
    let lastDate = parseInt(cacheStorage.getItem(transactionKey) || 0, 10)
    let minutes = 10

    if (lastDate + (60000 * minutes) > Date.now()) {
      if (!isServer) store.dispatch('cart/load')
    }

    if (isServer) clearInterval(cacheInterval)
  }, 60000) // every one minutes check for cart updates

  // on mini cart open or general cart ui action
  Vue.prototype.$bus.$on('cart-ui-action', () => {
    cacheStorage.setItem(transactionKey, Date.now())
  })

  store.subscribe((mutation, state) => {
    const type = mutation.type

    if (
      type.endsWith('cart/cart/LOAD') ||
      type.endsWith('cart/cart/ADD') ||
      type.endsWith('cart/cart/DEL') ||
      type.endsWith('cart/cart/UPD') ||
      type.endsWith('cart/cart/UPD_PROPS') ||
      type.endsWith('ui/setMicrocart')
    ) {
      cacheStorage.setItem(transactionKey, Date.now())
    }
  })

  store.commit('cart/checkFreeShipping')
}
