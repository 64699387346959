import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import CartState from '../types/CartState'

const mutations: MutationTree<CartState> = {
  [types.CART_PROCESSING] (state, value) {
    state.processing = value
  },
  [types.CART_SOURCE] (state, value) {
    state.source = value
  },
  checkFreeShipping (state, rootState) {
    if (!state.cartItems.length) { state.hasFreeShipping = false; return } // no items in cart
    if (!state.freeShippingThreshold || state.freeShippingThreshold < 0) { state.hasFreeShipping = false; return } // no free shipping threshold set

    // update difference so it's at least correct regardless of shipping status
    state.freeShippingDifference = state.freeShippingThreshold - (state.platformTotals.subtotal ? state.platformTotals.subtotal : 0)

    // first check customer loyalty tier, maybe we can skip looking at products
    let freeShipTiers = ['ruby', 'diamond', 'black_diamond']

    if (rootState && rootState.user && freeShipTiers.includes(rootState.user.current.extension_attributes.loyalty_tier)) {
      state.hasFreeShipping = true
      return
    }

    if (state.cartItems.length) {
      for (let i = 0; i < state.cartItems.length; i++) {
        // if order contains a Bleu item (check order skus against productList for brand_id), ship free (i.e. return 0)
        // or if order contains an "Intro Offer" (it's an attribute on the item in productList) return 0
        if (state.cartItems[i].brand_id === 5 || state.cartItems[i].intro_offer === 1) {
          // instead of returning a message, set the freeShippingDifference in state
          state.hasFreeShipping = true
          return
        }
      }

      // otherwise check cart total and return difference from threshold
      if (state.freeShippingDifference <= 0) {
        state.hasFreeShipping = true
        return
      }

      // all early return conditions failed, no free shipping
      state.hasFreeShipping = false
    }
  }
}

export default mutations
