import i18n from '@vue-storefront/i18n'
import assignProductOption from '../../../../helpers/assignProductOption'

function _getDifflogPrototype () {
  return { items: [], serverResponses: [], clientNotifications: [] }
}

export default {
  getLoyaltyCartItem ({dispatch, getters}, sku) {
    return getters.getLoyaltyCartItems.find(p => p.sku === sku)
  },
  async upsertLoyaltyItem ({getters, dispatch}, {product, qty, upsert = true}) {
    let productToAdd = Object.assign({}, product)
    let diffLog = _getDifflogPrototype()
    let computedQty = 0
    let currentPointsQty = 0
    let newPointsQty = parseInt(qty, 10)

    // can't spend
    if (!productToAdd.point_can_spend) {
      diffLog.clientNotifications.push({
        type: 'error',
        message: i18n.t('Unable to spend points on this item!'),
        action1: { label: i18n.t('OK') }
      })
      return diffLog
    }

    let clientItem = await dispatch('getItem', productToAdd.sku)

    if (clientItem) { // existing item
      productToAdd.server_item_id = clientItem.server_item_id
      computedQty = clientItem.qty
      currentPointsQty = ((clientItem.product_option || {}).extension_attributes || {}).qty_using_points || 0

      if (upsert) {
        // remove existing pts from client qty
        if (currentPointsQty <= computedQty) {
          computedQty -= currentPointsQty
        }
        // add new points to client qty
        computedQty += newPointsQty
        // upsert qty
        assignProductOption(productToAdd, 'qty_using_points', newPointsQty)
      } else {
        // add new pts to client qty
        computedQty += newPointsQty

        // add new points to current points
        assignProductOption(productToAdd, 'qty_using_points', currentPointsQty + newPointsQty)
      }

      return dispatch('cartAddItem', { productToAdd: productToAdd, qty: computedQty, update: true })
    }

    assignProductOption(productToAdd, 'qty_using_points', newPointsQty)

    return dispatch('cartAddItem', { productToAdd: productToAdd, qty: qty, update: true })
  },
  async removeLoyaltyItem ({ dispatch, getters }, { product }) {
    let clientItem = await dispatch('getLoyaltyCartItem', product.sku)

    if (!clientItem) return

    let currentPointsQty = ((clientItem.product_option || {}).extension_attributes || {}).qty_using_points || 0

    if (currentPointsQty === clientItem.qty) {
      return dispatch('removeItem', clientItem)
    } else {
      return dispatch('upsertLoyaltyItem', {
        product: clientItem,
        qty: 0
      })
    }
  }
}
