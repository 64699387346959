import { ActionTree } from 'vuex'
import VersioningState from '../types/VersioningState'
import Vue from 'vue'

export const actions: ActionTree<VersioningState, any> = {
  update ({ state, commit }, modalDisplay) {
    let exclude = [
      'lbppro-shop/carts/current-cart-token',
      'lbppro-shop/user/current-token',
      'lbppro-shop/user/current-user',
      'lbppro-shop/user/current-refresh-token',
      'lbppro/version-upgrade',
      'versioning/versioning/version'
    ]

    // clear localstorage
    for (let i = 0, len = localStorage.length; i < len; ++i) {
      let key = localStorage.key(i)

      // TODO: make this more dynamic
      if (!exclude.includes(key)) {
        localStorage.removeItem(localStorage.key(i))
      }
    }

    Vue.prototype.$db.versioningCollection.setItem('version', state.version).catch((reason) => {
      console.error(reason)
    })

    Vue.prototype.$db.versioningCollection.setItem('modal', modalDisplay).catch((reason) => {
      console.error(reason)
    })

    if (window) {
      window.navigator.serviceWorker.getRegistrations()
        .then((registrations) => {
          registrations.forEach((registration) => {
            registration.unregister()
            window.location.reload(true)
          })
        })
    }
  }
}
