import Vue from 'vue'
import { ActionTree } from 'vuex'
import * as types from './mutation-types'
import config from 'config'
import fetch from 'isomorphic-fetch'
import {isServer, processURLAddress} from '@vue-storefront/core/helpers'
import { Logger } from '@vue-storefront/core/lib/logger'
import rootStore from '@vue-storefront/core/store'
import StoredCardsState from '../types/StoredCardsState'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export const actions: ActionTree<StoredCardsState, any> = {
  getStoredCards (context) {
    let token = rootStore.getters['user/getUserToken']
    const apiUrl = config.storedCardsPayment.endpoint.getCards.replace('{{token}}', token) + '&storeCode=' + currentStoreView().storeCode

    return new Promise<Response>((resolve, reject) => {
      fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      }).then(resp => resp.json()).then(json => {
        if (json.result) {
          // @ts-ignore
          // eslint-disable-next-line func-call-spacing
          let locations : any = rootStore.state.user.current.extension_attributes.locations_data || []

          // @ts-ignore
          // eslint-disable-next-line no-unexpected-multiline
          (json.result.items || []).map(card => {
            card.brand_code = ((card.extension_attributes || {}).brand || {}).code
            card.location = locations.find(location => location.location_id == card.location_id)
          })

          context.commit(types.ALL_CARDS, json.result.items || [])
        }
        resolve(json.result.items || [])
      }).catch(err => {
        reject(err)
        Logger.error(err)
      })
    })
  },
  setCustomerLocation ({ commit }, { location }) {
    commit(types.CUSTOMER_LOCATION, location)
  },
  async syncCards (context) {
    if (isServer) return

    const cache = Vue.prototype.$db.storedCardsCollection
    const customerCards = await cache.getItem('customer-cards')

    if (customerCards) context.commit(types.ALL_CARDS, customerCards)
  },
  async syncLocation (context) {
    if (isServer) return

    const cache = Vue.prototype.$db.storedCardsCollection
    const customerLocation = await cache.getItem('customer-location')

    if (customerLocation) context.commit(types.CUSTOMER_LOCATION, customerLocation)
  }
}
