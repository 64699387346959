<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { datadogRum } from '@datadog/browser-rum'

const DefaultLayout = () => import(/* webpackChunkName: "vsf-layout-default" */ './layouts/Default')
const SplashLayout = () => import(/* webpackChunkName: "vsf-layout-default" */ './layouts/Splash')

export default {
  data () {
    return {
      ordersData: []
    }
  },
  computed: {
    ...mapState({
      overlayActive: state => state.ui.overlay,
      version: state => state.versioning.version
    }),
    layout () {
      return `${(this.$route.meta.layout || 'default')}-layout`
    }
  },
  created () {
    datadogRum.init({
      applicationId: '7d08f7bc-23cb-4fef-83f6-89ba1518cd21',
      clientToken: 'pubba496b3c6285836d487a183a83b688b2',
      site: 'datadoghq.com',
      service: 'vsf-lbppro',
      env: 'production',
      version: this.version,
      sampleRate: 100,
      trackInteractions: true
    })

    this.$bus.$on('user-after-loggedin', (data) => {
      if (data) {
        datadogRum.addRumGlobalContext('usr', {
          id: data.id,
          email: data.email,
          groupId: data.group_id
        })
      }
    })
  },
  components: {
    DefaultLayout,
    SplashLayout
  }
}
</script>
