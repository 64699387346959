import { isServer } from '@vue-storefront/core/helpers'
import { Route } from 'vue-router';
import rootStore from '@vue-storefront/core/store'
import debounce from 'lodash-es/debounce'

const loadLoyaltyData = debounce(function () {
  rootStore.dispatch('loyalty/loyaltyCustomer')
  rootStore.dispatch('loyalty/loyaltyDashboard')
}, 10000, { leading: true, trailing: false })

export function afterEach (to: Route, from: Route) {
  if (!isServer && rootStore.getters['user/isLoggedIn'] && from.name) {
    loadLoyaltyData()
  }
}
