import { Route } from 'vue-router'
import { isServer } from '@vue-storefront/core/helpers'

export async function beforeEach (to: Route, from: Route, next) {
  const cacheRoute = to.matched.some(route => route.name === 'cache-clear')

  if (cacheRoute && !isServer) {
    console.log('clearing...')
    navigator.serviceWorker.getRegistrations()
      .then(registrations => {
        registrations.forEach(registration => {
          registration.unregister()
          console.log(registration)
          window.location.reload(true)
        })
      })
    next()
  } else {
    next()
  }
}
