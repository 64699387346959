import {SN_VERSIONING, SET_READY_TO_UPDATE, SET_VERSION, SET_UI, SET_MODAL} from '../store/mutation-types'

export async function afterRegistration ({ Vue, config, store, isServer }) {
  if (!isServer) {
    let clientVersion = await Vue.prototype.$db.versioningCollection.getItem('version')
    let modal = await Vue.prototype.$db.versioningCollection.getItem('modal')
    let applicationVersion = store.state.version
    let updateReady = clientVersion && (clientVersion !== applicationVersion)

    store.commit(`${SN_VERSIONING}/` + SET_VERSION, applicationVersion)
    store.commit(`${SN_VERSIONING}/` + SET_READY_TO_UPDATE, updateReady)
    store.commit(`${SN_VERSIONING}/` + SET_UI, updateReady)
    store.commit(`${SN_VERSIONING}/` + SET_MODAL, modal)
  }
}
