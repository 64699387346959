export async function afterRegistration ({ Vue, config, store, isServer }) {
  if (!isServer && config.attentive && config.attentive.id) {
    // before body end insert html for attentive
    const attentiveHtml = document.createElement('div')

    attentiveHtml.setAttribute('id', 'attentiveMagentoPage')
    attentiveHtml.setAttribute('style', 'display: none;')
    attentiveHtml.innerHTML = ''
    document.body.appendChild(attentiveHtml)

    // inject the attentive script
    const script = document.createElement('script')

    script.setAttribute('id', 'attentive')
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('async', 'true')
    script.setAttribute('defer', 'true')
    script.setAttribute('src', `//cdn.attn.tv/${config.attentive.id}/dtag.js`)
    document.head.appendChild(script)

    store.subscribe((mutation, state) => {
      // on page change
      if (mutation.type === 'route/ROUTE_CHANGED') {
        // if is product route and product is loaded base64 product data and add inside hidden div
        if (state.route.name === 'product' && state.product.current_product) {
          const productInfoHtml = document.createElement('div')

          productInfoHtml.setAttribute('style', 'display: none;')
          productInfoHtml.setAttribute('id', 'attnProductInfo')
          productInfoHtml.innerHTML = btoa(JSON.stringify(state.product.current_product))
          document.body.appendChild(productInfoHtml)
        } else {
          // remove element if not product page
          const attentiveProduct = document.getElementById('attnProductInfo')

          if (attentiveProduct) {
            attentiveProduct.remove()
          }
        }
      }

      // if order success page
      if (mutation.type === 'checkout/PLACE_ORDER') {
        const orderInfoHtml = document.createElement('div')

        orderInfoHtml.setAttribute('style', 'display: none;')
        orderInfoHtml.setAttribute('id', 'attnOrderInfo')
        orderInfoHtml.innerHTML = ''
        document.body.appendChild(orderInfoHtml)
      } else {
        // remove element if not order success page
        const attentiveOrder = document.getElementById('attnOrderInfo')

        if (attentiveOrder) {
          attentiveOrder.remove()
        }
      }
    })
  }
}
