import * as types from '../mutation-types'
import config from 'config'
import i18n from '@vue-storefront/i18n'
import merge from 'lodash-es/merge'
import pick from 'lodash-es/pick'
import rootStore from '@vue-storefront/core/store'
import {TaskQueue} from '@vue-storefront/core/lib/sync'
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'
import { Logger } from '@vue-storefront/core/lib/logger'
import {router} from '@vue-storefront/core/app'
import {currentStoreView, localizedRoute} from '@vue-storefront/core/lib/multistore'
import assignProductOption from 'src/themes/lbppro/helpers/assignProductOption'
import {datadogRum} from '@datadog/browser-rum'

function _getDifflogPrototype () {
  return {items: [], serverResponses: [], clientNotifications: []}
}

export default {
  // productList: array of [product (Object), money qty (Number), loyalty qty (Number)]
  async bulkAddItems ({ commit, dispatch, getters }, {productsToAdd} ) {
    try {
      if (!productsToAdd.length) {
        dispatch('notification/spawnNotification', {
          type: 'error',
          message: i18n.t('No products selected.'),
          action1: { label: i18n.t('OK') }
        }, {root: true})
        return ''
      }
      let token = rootStore.getters['user/getUserToken']
      let cartId = parseInt(rootStore.getters['cart/getCartToken'], 10)

      let reqBody = {cartItems: [], token, cartId}
      for (const arr of productsToAdd) {
        let fetchedCartItem = await dispatch('getItem', arr[0].sku) || {}
        let cartItem = {
          item_id: fetchedCartItem.server_item_id || fetchedCartItem.item_id,
          quoteId: fetchedCartItem.server_cart_id || getters.getCartToken,
          sku: fetchedCartItem.parentSku && config.cart.setConfigurableProductOptions ? fetchedCartItem.parentSku : arr[0].sku,
          qty: parseFloat(arr[1] + arr[2])
        }
        if (arr[2] > 0) {
          assignProductOption(cartItem, 'qty_using_points', arr[2])
        }
        reqBody.cartItems.push(cartItem)
      }

      const task = await TaskQueue.execute({
        url: '/api/ext/vsf-magento-api/bulk-add', // sync the cart
        payload: {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          body: JSON.stringify(reqBody)
        },
        silent: true
      })

      if (task.resultCode === 200) {
        dispatch('notification/spawnNotification', {
          type: 'success',
          message: i18n.t('Your products have been added to the cart!'),
          action1: {
            label: i18n.t('Proceed to checkout'),
            action: () => {
              router.push(localizedRoute('/cart', currentStoreView().storeCode))
            }
          }
        }, {root: true})

        dispatch('cartPull', { update: true })
      } else {
        let message = null

        if (Object.prototype.toString.call(task.result) === '[object String]') {
          message = task.result
        } else if (task.result.errorMessage) {
          message = task.result.errorMessage
        }
        dispatch('notification/spawnNotification', {
          type: 'error',
          message: i18n.t(message || 'There was an error adding your products to the cart.'),
          action1: { label: i18n.t('OK') }
        }, {root: true})


        dispatch('cartPull', { update: true })
      }
    } catch (err) {
      // generic error so there's no silent failures for user
      dispatch('notification/spawnNotification', {
        type: 'error',
        message: i18n.t('There was an error adding your products to the cart.'),
        action1: { label: i18n.t('OK') }
      }, {root: true})
    }
  },
  async setSource ({commit}, source) {
    commit(types.CART_SOURCE, source)
  },
  async cartAddItem ({commit, dispatch, getters}, { productToAdd, qty, update }) {
    commit(types.CART_PROCESSING, true)
    const diffLog = _getDifflogPrototype()

    if (!productToAdd) {
      console.log(productToAdd, 'No product provided.')
      return diffLog
    }

    let productQuantity = productToAdd.qty

    let cartItem = await dispatch('getItem', productToAdd.sku) || {}

    if (cartItem.qty && !update) {
      productQuantity += cartItem.qty
    }

    const task = await TaskQueue.execute({ url: config.cart.updateitem_endpoint, // sync the cart
      payload: {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify({
          cartItem: {
            item_id: productToAdd.server_item_id || productToAdd.item_id || cartItem.item_id,
            quoteId: productToAdd.server_cart_id || getters.getCartToken,
            sku: productToAdd.parentSku && config.cart.setConfigurableProductOptions ? productToAdd.parentSku : productToAdd.sku,
            qty: parseFloat(update ? qty : productQuantity),
            product_option: productToAdd.product_option,
            extension_attributes: productToAdd.hasOwnProperty('using_points') && productToAdd.using_points ? {
              using_points: true
            } : null
          }
        })
      },
      silent: true
    })

    if (task.resultCode === 200) {
      const isThisNewItemAddedToTheCart = !getters.getCartItems.find(y => y.server_item_id)

      const notificationData = {
        type: 'success',
        message: isThisNewItemAddedToTheCart ? i18n.t('Product has been added to the cart!') : i18n.t('Product quantity has been updated!'),
        action1: { label: i18n.t('OK') },
        action2: !config.externalCheckout ? {
          label: i18n.t('Proceed to checkout'),
          action: () => {
            router.push(localizedRoute('/cart', currentStoreView().storeCode))
            // dispatch('goToCheckout')
          }
        } : null
      }

      diffLog.clientNotifications.push(notificationData)

      datadogRum.addAction('Add to Cart', task.result)

      dispatch('cartPull', { update })
    } else {
      let message = null

      if (Object.prototype.toString.call(task.result) === '[object String]') {
        message = task.result
      } else if (task.result.errorMessage) {
        message = task.result.errorMessage
      }

      dispatch('notification/spawnNotification', {
        type: 'error',
        message: i18n.t(message || 'There was an error adding your product to the cart.'),
        action1: { label: i18n.t('OK') }
      }, {root: true})

      datadogRum.addError('Add to Cart', task)

      dispatch('cartPull', { update })
    }

    commit(types.CART_PROCESSING, false)

    return diffLog
  },
  async cartRemoveItem ({commit, dispatch, getters}, { product, update }) {
    commit(types.CART_PROCESSING, true)
    const diffLog = _getDifflogPrototype()

    if (!product) {
      console.log(product, 'No product provided.')
      return diffLog
    }

    const task = await TaskQueue.execute({ url: config.cart.deleteitem_endpoint, // sync the cart
      payload: {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify({
          cartItem: {
            sku: product.sku,
            item_id: product.server_item_id || product.item_id,
            quoteId: product.server_cart_id || getters.getCartToken
          }
        })
      },
      silent: true
    })

    if (task.resultCode === 200) {
      datadogRum.addAction('Remove from Cart', task.result)
      await dispatch('cartPull', { update })
      const notificationData = {
        type: 'success',
        message: i18n.t('Product has been removed from the cart!'),
        action1: { label: i18n.t('OK') }
      }

      diffLog.clientNotifications.push(notificationData)
    } else {
      let message = null

      if (Object.prototype.toString.call(task.result) === '[object String]') {
        message = task.result
      } else if (task.result.errorMessage) {
        message = task.result.errorMessage
      }

      dispatch('notification/spawnNotification', {
        type: 'error',
        message: i18n.t(message || 'There was an error removing your product from the cart.'),
        action1: { label: i18n.t('OK') }
      }, {root: true})

      datadogRum.addError('Remove from Cart', task)

      dispatch('cartPull', { update })
    }

    commit(types.CART_PROCESSING, false)

    return diffLog
  },
  async cartPull ({commit, dispatch, getters}, { update }) {
    commit(types.CART_SET_SYNC)
    commit(types.CART_PROCESSING, true)
    const task = await TaskQueue.execute({ url: config.cart.pull_endpoint, // sync the cart
      payload: {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors'
      },
      silent: true
    })

    if (task.resultCode === 200) {
      for (const serverItem of task.result) {
        let res = task.result.filter(x => x.sku === serverItem.sku)
        if (res.length > 1) {
          datadogRum.addError('Cart Pull Duplicate Detected', task.result)
          break
        }
      }

      let products = await dispatch('product/list', {
        query: (new SearchQuery()).applyFilter({
          key: 'sku',
          value: {'in': task.result.map(x => x.sku)}
        }),
        sort: 'position:desc',
        size: 1000
      }, {root: true})

      let virtualSkus = (products.items || []).filter((y) => y.parent_sku).map(x => x.parent_sku)

      virtualSkus = Array.prototype.concat(...virtualSkus) // flatten

      let parentProducts: any = {}
      let includeFieldsParent = ['id', 'sku', 'type_id', 'product_type', 'configurable_options', 'product_option', 'image', 'slug', 'url_key', 'url_path', 'parent_sku']

      if (virtualSkus.length) {
        parentProducts = await dispatch('product/list', {
          query: (new SearchQuery()).applyFilter({
            key: 'sku',
            value: {'in': virtualSkus}
          }),
          sort: 'position:desc',
          size: 1000,
          includeFields: includeFieldsParent
        }, {root: true})
      }

      if (update) {
        let mappedProducts = products.items.map((x) => {
          if (!x) {
            return x
          }

          if (!task.result) {
            return x
          }

          let item = task.result.find(y => x.sku === y.sku) || {}

          item.parentProduct = pick(
            (parentProducts.items || []).find(y => y.sku === ((x.parent_sku || []).join())),
            includeFieldsParent
          )

          return merge(x, item)
        }).sort((a, b) => a.item_id - b.item_id) // sort by item_id

        let updated = []

        for (const product of mappedProducts) {
          let record = getters.getCartItems.find(p => (p.sku === product.sku || (p.server_item_id && p.server_item_id === product.server_item_id)))

          if (record) {
            commit(types.CART_UPD_ITEM, {product: product, qty: product.qty})
            commit(types.CART_UPD_ITEM_PROPS, {
              product: {
                'sku': product.sku,
                'server_item_id': product.server_item_id,
                'product_option': product.product_option,
                'extension_attributes': product.extension_attributes
              }
            })
          } else {
            commit(types.CART_ADD_ITEM, { product: product })
          }
          updated.push(product.sku)
        }

        for (const product of (getters.getCartItems.filter(x => !updated.includes(x.sku)) || [])) {
          commit(types.CART_DEL_ITEM, {product})
        }
      } else {
        // merge product structure with cart structure
        await commit(types.CART_LOAD_CART, products.items.map((x) => {
          let item = task.result.find(y => x.sku === y.sku) || {}

          item.parentProduct = pick(
            (parentProducts.items || []).find(y => y.sku === ((x.parent_sku || []).join())),
            includeFieldsParent
          )

          return merge(x, item)
        }).sort((a, b) => a.item_id - b.item_id))
      }

      for (const product of getters.getCartItems) {
        dispatch('stock/queueCheck', { product }, { root: true })
      }

      // async sync totals
      dispatch('syncTotals').then(() => {
        commit(types.CART_SET_ITEMS_HASH, getters.getCurrentCartHash) // update the cart hash
        commit('checkFreeShipping') // check free shipping
      })
    } else {
      const MAX_BYPASS_COUNT = 10
      let _connectBypassCount = 0

      Logger.error(task.result, 'cart') // override with guest cart()

      if (_connectBypassCount < MAX_BYPASS_COUNT) {
        Logger.log('Bypassing with guest cart' + _connectBypassCount, 'cart')()
        _connectBypassCount = _connectBypassCount + 1
        await dispatch('connect', { guestCart: true })
        Logger.error(task.result, 'cart')()
      }
    }

    commit(types.CART_PROCESSING, false)
    return task
  },
  async clearCart ({ dispatch, getters, commit }) {
    let token = rootStore.getters['user/getUserToken']
    let cartId = rootStore.getters['cart/getCartToken']

    commit(types.CART_PROCESSING, true)

    let task = await TaskQueue.execute({
      url: config.cart_clear_endpoint || '/api/ext/vsf-magento-api/clear', // sync the cart
      payload: {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
        mode: 'cors',
        body: JSON.stringify({token: token, cartId: parseInt(cartId, 10)})
      },
      silent: true
    })

    commit(types.CART_PROCESSING, false)
    await dispatch('clear')

    datadogRum.addAction('Cart Cleared', task)

    return task
  }
}
