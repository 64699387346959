import * as localForage from 'localforage'
import UniversalStorage from '@vue-storefront/core/store/lib/storage'

export function beforeRegistration({ Vue, config, store, isServer }) {
  Vue.prototype.$db.brandsCollection = new UniversalStorage(localForage.createInstance({
    name: 'brands',
    storeName: 'brands',
    driver: localForage['LOCALSTORAGE']
  }))
}
