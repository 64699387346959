import { productModule } from './store/product'
import { categoryModule } from './store/category'
import { stockModule } from './store/stock'
import { beforeEach } from './router/beforeEach'

export const KEY = 'catalog'
export const CatalogExtend = {
  key: KEY,
  store: { modules: [
    { key: 'product', module: productModule },
    { key: 'category', module: categoryModule },
    { key: 'stock', module: stockModule }
  ] },
  router: { beforeEach }
}
