import RootState from '@vue-storefront/core/types/RootState'
import MyAccountState from '../types/MyAccountState'
import { ActionTree } from 'vuex'
import * as types from './mutation-types'

export const actions: ActionTree<MyAccountState, RootState> = {
  updatePerPage (context, perPage) {
    context.commit(types.MYACCOUNT_CHANGE_PERPAGE_PAGINATION, perPage)
  },
  updateCurrentPagePagination (context, currentPage) {
    context.commit(types.MYACCOUNT_CHANGE_CURRENT_PAGINATION, currentPage)
  },
  resetCurrentPagePagination ({ commit }, useCache = true) {
    commit(types.MYACCOUNT_RESET_CURRENT_PAGINATION, 1)
  }
}
