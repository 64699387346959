import config from 'config'
import rootStore from '@vue-storefront/core/store'

export const DEFAULT_SHEET_NAME = 'Current Sheet'
export const CART_SOURCE_NAME = 'quick-order'

export function getRulePrice (rootState, product) {
  let currentUser = rootState.user.current
  let customerIsLogged = rootStore.getters['user/isLoggedIn']
  let customerGroup = customerIsLogged ? currentUser.group_id : ''

  if (product.override_point_redemption_price) {
    return product.point_redemption_price || false
  }

  if (product.tier_prices && product.tier_prices.length) {
    let tierPriceByCustomer = product.tier_prices.find(price => price.customer_group_id === customerGroup)

    if (!tierPriceByCustomer) return false

    return tierPriceByCustomer.extension_attributes.website_id === config.defaultWebsiteId || tierPriceByCustomer.extension_attributes.website_id === 0
      ? tierPriceByCustomer.value / tierPriceByCustomer.qty
      : false
  }

  if (!product.catalog_rule_price || !config.priceRulesEnabled) return false

  return (product.catalog_rule_price[customerGroup])
    ? parseFloat(product.catalog_rule_price[customerGroup].rule_price).toFixed(2)
    : false
}

export function blankOrder () {
  // just a convenient place to store a blank order for creating and clearing orders
  return {
    id: null,
    name: DEFAULT_SHEET_NAME,
    created_at: null,
    updated_at: null,
    order_data: {}
  }
}

export function getAllowedBrandsHelper (currentUser) {
  const brandLocations = currentUser ? ((currentUser || {}).extension_attributes || {}).locations_data : []
  return Array.from(new Set(brandLocations.filter(loc => loc.status === true).map(loc => loc.brand_id)))
}
