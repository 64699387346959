import config from 'config'

const Home = () => import(/* webpackChunkName: "vsf-home" */ 'theme/pages/Home.vue')
const Search = () => import(/* webpackChunkName: "vsf-home" */ 'theme/pages/Search.vue')
const SignIn = () => import(/* webpackChunkName: "vsf-auth" */ 'theme/pages/Auth/SignIn.vue')
const ForgotYourPassword = () => import(/* webpackChunkName: "vsf-static-forgot" */ 'theme/pages/Auth/ForgotYourPassword.vue')
const ResetPassword = () => import(/* webpackChunkName: "vsf-static-forgot" */ 'theme/pages/Auth/ResetPassword.vue')
const ErrorPage = () => import(/* webpackChunkName: "vsf-error" */ 'theme/pages/Error.vue')
const Product = () => import(/* webpackChunkName: "vsf-product" */ 'theme/pages/Product.vue')
const ShopAll = () => import(/* webpackChunkName: "vsf-shop" */ 'theme/pages/ShopAll.vue')
const Category = () => import(/* webpackChunkName: "vsf-category" */ 'theme/pages/Category.vue')
const Checkout = () => import(/* webpackChunkName: "vsf-checkout" */ 'theme/pages/Checkout.vue')
const Cart = () => import(/* webpackChunkName: "vsf-cart" */ 'theme/pages/Cart.vue')
// const Welcome = () => import(/* webpackChunkName: "vsf-static-welcome" */ 'theme/pages/Welcome.vue')
const WelcomeNext = () => import(/* webpackChunkName: "vsf-static-welcome" */ 'theme/pages/WelcomeNext.vue')
const ClearCache = () => import(/* webpackChunkName: "vsf-static-welcome" */ 'theme/pages/ClearCache.vue')
const StaticRewardsCart = () => import(/* webpackChunkName: "vsf-cart" */ 'theme/pages/StaticRewardsCart.vue')
const RewardsCart = () => import(/* webpackChunkName: "vsf-cart" */ 'theme/pages/RewardsCart.vue')
// const EventsList = () => import(/* webpackChunkName: "vsf-product" */ 'theme/pages/EventsList.vue')
// const Event = () => import(/* webpackChunkName: "vsf-product" */ 'theme/pages/Event.vue')
const StaticMaterials = () => import(/* webpackChunkName: "vsf-product" */ 'theme/pages/StaticMaterials.vue')
const MyAccount = () => import(/* webpackChunkName: "vsf-my-account" */ 'theme/pages/MyAccount.vue')
const Storyblok = () => import(/* webpackChunkName: "vsf-custom-page" */ 'theme/pages/Storyblok')
const Rewards = () => import(/* webpackChunkName: "vsf-account" */ 'theme/pages/Rewards.vue')
const SubscriptionForm = () => import(/* webpackChunkName: "vsf-subscription" */ 'theme/pages/SubscriptionForm.vue')
const QuickOrder = () => import(/* webpackChunkName: "vsf-quick-order" */ 'theme/pages/QuickOrder.vue')
const Refill = () => import(/* webpackChunkName: "vsf-refill" */ 'theme/pages/Refill.vue')

// see layouts/Default.vue to add non-restricted routes
let routes = [
  { name: 'home', path: '/', component: Home, alias: '/pwa.html', meta: { requiresAuth: true } },
  { name: 'advanced-search', path: '/catalogsearch/advanced', component: Search, meta: { requiresAuth: true } },
  { name: 'about', path: '/about', component: Storyblok, meta: { requiresAuth: true } },
  { name: 'contact', path: '/contact', component: Storyblok, meta: { requiresAuth: true } },
  { name: 'faq', path: '/faq', component: Storyblok, meta: { requiresAuth: true } },
  { name: 'journal', path: '/journal', component: Storyblok, meta: { requiresAuth: true } },
  { name: 'press', path: '/press', component: Storyblok, meta: { requiresAuth: true } },
  { name: 'delivery-and-returns/', path: '/delivery-and-returns', component: Storyblok, meta: { requiresAuth: true } },
  { name: 'sign-in', path: '/customer/account/login', component: SignIn, meta: { page: 'Login', title: 'Login' } },
  { name: 'forgotpassword', path: '/customer/account/forgotpassword', component: ForgotYourPassword, meta: { page: 'Forgot Your Password', title: 'Forgot Your Password' } },
  { name: 'createpassword', path: '/customer/account/createpassword/:resetToken', component: ResetPassword, meta: { page: 'Create Password', title: 'Create Password' } },
  { name: 'welcome', path: '/welcome', component: WelcomeNext, meta: { layout: 'splash', title: 'ARTIST-DRIVEN BEAUTY BRANDS' } },
  { name: 'clear-cache', path: '/clear-cache', component: ClearCache, meta: { layout: 'blank', title: 'Clearing Cache' } },
  { name: 'shop-all', path: '/shop/all', component: ShopAll, meta: { page: 'Shop', title: 'Full product line', requiresAuth: true } },
  { name: 'cart', path: '/cart', component: Cart, meta: { requiresAuth: true, page: 'Cart', title: 'Cart', 'description': 'Shopping Cart' } },
  { name: 'static-rewards-cart', path: '/static/rewards-cart', component: StaticRewardsCart, meta: { requiresAuth: true } },
  { name: 'rewards-cart', path: '/rewards-cart', component: RewardsCart, meta: { requiresAuth: true } },
  { name: 'subscriptions', path: '/subscriptions', component: SubscriptionForm, meta: { requiresAuth: true, page: 'Subscriptions', title: 'Subscriptions' } },
  { name: 'r-and-co-stockist', path: '/r-and-co-stockist', component: Storyblok, meta: { requiresAuth: true } },
  { name: 'r-and-color-stockist', path: '/r-and-color-stockist', component: Storyblok, meta: { requiresAuth: true } },
  { name: 'v76-stockist', path: '/v76-stockist', component: Storyblok, meta: { requiresAuth: true } },
  { name: 'smith-and-cult-stockist', path: '/smith-and-cult-stockist', component: Storyblok, meta: { requiresAuth: true } },
  { name: 'bleu-stockist', path: '/bleu-stockist', component: Storyblok, meta: { requiresAuth: true } },
  { name: 'events', path: '/education/events', component: Storyblok, meta: { requiresAuth: true } },
  { name: 'refill', path: '/refill', component: Refill, meta: { requiresAuth: true } },
  {
    path: '/materials/r-and-co/merchandising-schematics',
    component: StaticMaterials,
    props: { activeBlock: 'MerchandisingSchematics', activeBrand: 'rco' },
    meta: { requiresAuth: true }
  },
  {
    path: '/materials/smith-and-cult/merchandising-schematics',
    component: StaticMaterials,
    props: { activeBlock: 'MerchandisingSchematics', activeBrand: 'smith-cult' },
    meta: { requiresAuth: true }
  },
  {
    path: '/materials/v76/merchandising-schematics',
    component: StaticMaterials,
    props: { activeBlock: 'MerchandisingSchematics', activeBrand: 'vaughn' },
    meta: { requiresAuth: true }
  },
  {
    path: '/materials/r-and-co/news-and-press',
    component: StaticMaterials,
    props: { activeBlock: 'NewsPress', activeBrand: 'rco' },
    meta: { requiresAuth: true }
  },
  {
    path: '/materials/smith-and-cult/news-and-press',
    component: StaticMaterials,
    props: { activeBlock: 'NewsPress', activeBrand: 'smith-cult' },
    meta: { requiresAuth: true }
  },
  {
    path: '/materials/v76/news-and-press',
    component: StaticMaterials,
    props: { activeBlock: 'NewsPress', activeBrand: 'vaughn' },
    meta: { requiresAuth: true }
  },
  {
    path: '/materials/r-and-co/product-assets',
    component: StaticMaterials,
    props: { activeBlock: 'ProductAssets', activeBrand: 'rco' },
    meta: { requiresAuth: true }
  },
  {
    path: '/materials/smith-and-cult/product-assets',
    component: StaticMaterials,
    props: { activeBlock: 'ProductAssets', activeBrand: 'smith-cult' },
    meta: { requiresAuth: true }
  },
  {
    path: '/materials/v76/product-assets',
    component: StaticMaterials,
    props: { activeBlock: 'ProductAssets', activeBrand: 'vaughn' },
    meta: { requiresAuth: true }
  },
  {
    path: '/materials/r-and-co/social-assets',
    component: StaticMaterials,
    props: { activeBlock: 'SocialAssets', activeBrand: 'rco' },
    meta: { requiresAuth: true }
  },
  { name: 'checkout', path: '/checkout', component: Checkout, meta: { requiresAuth: true } },
  { name: 'my-account', path: '/customer/account', component: MyAccount, props: { activeBlock: 'MyDashboard' }, meta: { requiresAuth: true } },
  { name: 'my-profile', path: '/customer/account/edit', component: MyAccount, props: { activeBlock: 'MyProfile' }, meta: { requiresAuth: true } },
  { name: 'salon-edit', path: '/customer/account/salon/edit', component: MyAccount, props: { activeBlock: 'SalonEdit' }, meta: { requiresAuth: true } },
  { name: 'salon-admin', path: '/salonadmin', component: MyAccount, props: { activeBlock: 'SalonAdmin' }, meta: { requiresAuth: true } },
  { name: 'stored-credit-cards', path: '/storedpayments/customer/payments', component: MyAccount, props: { activeBlock: 'StoredCreditCards' }, meta: { requiresAuth: true } },
  { name: 'webprofit-share', path: '/customer/webprofit', component: MyAccount, props: { activeBlock: 'WebprofitShare' }, meta: { requiresAuth: true } },
  { name: 'webprofit-detail', path: '/customer/webprofit/detail', component: MyAccount, props: { activeBlock: 'WebprofitDetail' }, meta: { requiresAuth: true } },
  { name: 'classes-upcoming', path: '/customer/classes/upcoming', component: MyAccount, props: { activeBlock: 'UpcomingEvents' }, meta: { requiresAuth: true } },
  { name: 'classes-history', path: '/customer/classes/history', component: MyAccount, props: { activeBlock: 'PastEvents' }, meta: { requiresAuth: true } },
  { name: 'classes-requests', path: '/customer/classes/requests', component: MyAccount, props: { activeBlock: 'InSalonClasses' }, meta: { requiresAuth: true } },
  { name: 'classes-detail', path: '/customer/classes/detail/id/:classesId', component: MyAccount, props: { activeBlock: 'ClassDetails' }, meta: { requiresAuth: true } },
  { name: 'classes-tickets', path: '/customer/classes/tickets/id/:ticketId', component: MyAccount, props: { activeBlock: 'ClassTickets' }, meta: { requiresAuth: true } },
  { name: 'my-address', path: '/customer/address', component: MyAccount, props: { activeBlock: 'MyAddressBook' }, meta: { requiresAuth: true } },
  { name: 'my-newsletter', path: '/newsletter/manage', component: MyAccount, props: { activeBlock: 'MyNewsletter' }, meta: { requiresAuth: true } },
  { name: 'my-orders', path: '/sales/order/history', component: MyAccount, props: { activeBlock: 'MyOrders' }, meta: { requiresAuth: true } },
  { name: 'my-order', path: '/sales/order/:orderId', component: MyAccount, props: { activeBlock: 'MyOrder' }, meta: { requiresAuth: true } },
  { name: 'my-reseller-certificates', path: '/customer/reseller-certificates', component: MyAccount, props: { activeBlock: 'MyResellerCert' }, meta: { requiresAuth: true } },
  // { name: 'my-reseller-certificates', path: '/customer/reseller-certificates', component: MyAccount, props: { activeBlock: 'MyResellerCert' }, meta: { requiresAuth: true } },
  { name: 'my-rewards', path: '/customer/rewards', component: MyAccount, props: { activeBlock: 'MyRewards' }, meta: { requiresAuth: true } },
  { name: 'my-rewards-details', path: '/customer/rewards-details', component: MyAccount, props: { activeBlock: 'MyRewardsDetails' }, meta: { requiresAuth: true } },
  { name: 'affiliate-details', path: '/customer/affiliate/details', component: MyAccount, props: { activeBlock: 'AffiliateDetails' }, meta: { requiresAuth: true } },
  { name: 'affiliate-stylists', path: '/customer/affiliate/stylists', component: MyAccount, props: { activeBlock: 'AffiliateStylist' }, meta: { requiresAuth: true } },
  { name: 'stylists-form', path: '/customer/affiliate/stylists/create', component: MyAccount, props: { activeBlock: 'AffiliateStylistForm' }, meta: { requiresAuth: true } },
  { name: 'rewards', path: '/rewards-program/:section?', component: Rewards },
  { name: 'page-not-found', path: '/page-not-found', component: Storyblok, meta: { requiresAuth: true } },
  { name: 'error', path: '/error', component: ErrorPage, meta: { requiresAuth: true } },
  { name: 'quick-order', path: '/quick-order', component: QuickOrder, meta: { requiresAuth: true } }
]

if (!config.products.useShortCatalogUrls) {
  routes = routes.concat([
    { name: 'virtual-product', path: '/p/:parentSku/:slug', component: Product, meta: { requiresAuth: true } }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
    { name: 'bundle-product', path: '/p/:parentSku/:slug', component: Product, meta: { requiresAuth: true } }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
    { name: 'simple-product', path: '/p/:parentSku/:slug', component: Product, meta: { requiresAuth: true } }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
    { name: 'downloadable-product', path: '/p/:parentSku/:slug', component: Product, meta: { requiresAuth: true } }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
    { name: 'grouped-product', path: '/p/:parentSku/:slug', component: Product, meta: { requiresAuth: true } }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
    { name: 'configurable-product', path: '/p/:parentSku/:slug/:childSku', component: Product, meta: { requiresAuth: true } }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
    { name: 'product', path: '/p/:parentSku/:slug/:childSku', component: Product, meta: { requiresAuth: true } }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
    { name: 'category', path: '/c/:slug', component: Category, meta: { requiresAuth: true, categoryRoute: true } }
  ])
} else {
  routes = routes.concat([
    { name: 'virtual-product', path: '/:parentSku/:slug', component: Product, meta: { requiresAuth: true } }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
    { name: 'bundle-product', path: '/:parentSku/:slug', component: Product, meta: { requiresAuth: true } }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
    { name: 'simple-product', path: '/:parentSku/:slug', component: Product, meta: { requiresAuth: true } }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
    { name: 'downloadable-product', path: '/:parentSku/:slug', component: Product, meta: { requiresAuth: true } }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
    { name: 'grouped-product', path: '/:parentSku/:slug', component: Product, meta: { requiresAuth: true } }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
    { name: 'configurable-product', path: '/:parentSku/:slug/:childSku', component: Product, meta: { requiresAuth: true } }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
    { name: 'product', path: '/:parentSku/:slug/:childSku', component: Product, meta: { requiresAuth: true } }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
    { name: 'category', path: '/:slug', component: Category, meta: { requiresAuth: true, categoryRoute: true } }
  ])
}

export default routes
