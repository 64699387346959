import {CART_SOURCE_NAME} from '../helpers'
import {cacheStorage} from '../index'
import rootStore from '@vue-storefront/core/store'

export async function afterRegistration ({ Vue, config, store, isServer }) {
  if (isServer) return

  await store.dispatch('quickorder/setSource', await cacheStorage.getItem('source') || null)

  Vue.prototype.$bus.$on('order-after-placed', async (data) => {
    if (!(config.quickorder || {}).advanceFeatures) {
      await store.dispatch('quickorder/clearOrder')
      return
    }

    let source = await cacheStorage.getItem('source') || ''

    store.dispatch('quickorder/clearSource')

    // if cart was not initialized from quick order, bail
    if (!source.startsWith(CART_SOURCE_NAME)) return

    // save current sheet with order id
    // @ts-ignore
    let id = '#' + ((rootStore.state.order.last_order_confirmation || {}).confirmation || {}).orderNumber || data.order.order_id

    // clear source
    if (source.endsWith('custom')) {
      // "VP"
      // clone custom sheet, set order id
      return
    }

    if (await store.dispatch('quickorder/renameOrder', id)) {
      // generate new order with current sheet
      await store.dispatch('quickorder/createOrder')
    }
  })
}
