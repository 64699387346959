import { UrlState } from '@vue-storefront/core/modules/url/types/UrlState'
import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import { processMultipleDynamicRoutes, normalizeUrlPath, parametrizeRouteData } from '../helpers'

const actions: ActionTree<UrlState, RootState> = {
  /**
   * Register dynamic vue-router routes
   */
  async registerDynamicRoutes ({ state, dispatch }) {
    if (state.dispatcherMap) {
      processMultipleDynamicRoutes(state.dispatcherMap)
      for (const [url, routeData] of Object.entries(state.dispatcherMap)) {
        dispatch('registerMapping', { url, routeData })
      }
    }
  }
}

export default actions
