import { VueStorefrontModule, extendModule } from '@vue-storefront/core/lib/module'
import { checkoutExtend } from './checkout'
import { CatalogExtend } from './catalog'
import { Brands } from './brands'
import { Education } from './education'
import { MyAccount } from './myaccount'
import { LoyaltyProgram } from './loyalty-program'
import { StoredCards } from './stored-cards'
import { EducationEvents } from './education-events'
import { UiExtend } from './ui'
import { UrlExtend } from './url'
import { UserExtend } from './user'
import { CartExtend } from './cart'
// import { CartExtend as LoyaltyCartExtend } from './loyalty-cart'
import { CacheTools } from './cache-tools'
import { NotificationExtend } from './notification'
import { Versioning } from './versioning'
import { QuickOrder } from './quick-order'
import { Attentive } from './attentive'
import { DatadogLogging} from "./datadog-logging";
import { Recommended } from './recommended'

export function register () {
  extendModule(CartExtend)
  // extendModule(LoyaltyCartExtend)
  extendModule(checkoutExtend)
  extendModule(CatalogExtend)
  extendModule(UiExtend)
  extendModule(UrlExtend)
  extendModule(UserExtend)
  extendModule(NotificationExtend)
}

const LBPProModules: VueStorefrontModule[] = [
  DatadogLogging,
  Versioning,
  Brands,
  // Education,
  MyAccount,
  LoyaltyProgram,
  StoredCards,
  // EducationEvents,
  CacheTools,
  Versioning,
  QuickOrder,
  Attentive,
  Recommended
]

export default LBPProModules
